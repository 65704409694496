import { useState } from "react";
import { useProtectedAction } from "features/authentication";
import { useUserIssueReport } from "features/user";
import { Modal } from "ui";
import { ControlledTextarea, Select, Input, Form, useForm, yupResolver } from "forms";
import { object, string } from "yup";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import SendIcon from "@mui/icons-material/Send";

const schema = object().shape({
  reason: string().required(),
  page: string().when("reason", {
    is: "issue",
    then: string().required(),
  }),
  details: string().required(),
  api: string(),
});

export default function ReportFeedbackModal({
  onHide,
  isOrg = false,
  show = false,
  defaultReason = "",
  defaultPage = "",
  apiError = {},
}) {
  const [issueSent, setIssueSent] = useState(false);
  const { mutate, isPending } = useUserIssueReport();
  const {
    control,
    register,
    unregister,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const reason = watch("reason");

  const handleClose = () => {
    reset();
    setIssueSent(false);
    onHide();
  };

  const [handleSendIssue] = useProtectedAction((formData) =>
    mutate(formData, {
      onSuccess: () => setIssueSent(true),
      // Do not use default behaviour because this IS the default behaviour
      onError: () =>
        toast.error(<div data-testid="error-toast">Something went wrong, please try again.</div>),
    }),
  );

  return (
    <Modal
      isOpen={show}
      onOpenChange={handleClose}
      data-testid="report-feedback-modal"
      scrollBehavior="inside"
    >
      <Modal.ModalHeader>Give Us Feedback</Modal.ModalHeader>

      <Form onSubmit={handleSubmit(handleSendIssue)}>
        <Modal.ModalBody className="font-roman gap-5">
          {!issueSent ? (
            <>
              <Select
                {...register("reason")}
                label="Reason"
                defaultSelectedKeys={[defaultReason]}
                isInvalid={!!errors.reason}
                errorMessage={errors.reason?.message}
                onSelectionChange={({ currentKey }) => currentKey !== "issue" && unregister("page")}
              >
                <Select.SelectItem key="issue">Report an Issue</Select.SelectItem>
                <Select.SelectItem key="platform">Suggestion for the platform</Select.SelectItem>
                <Select.SelectItem key="brief">Suggestion for a brief</Select.SelectItem>
              </Select>

              {(reason === "issue" || defaultReason === "issue") && (
                <Select
                  {...register("page")}
                  label="Affecting Page"
                  defaultSelectedKeys={[defaultPage]}
                  isInvalid={!!errors.page}
                  errorMessage={errors.page?.message}
                >
                  <Select.SelectItem key="onboarding">Onboarding</Select.SelectItem>
                  <Select.SelectItem key="profile">Profile page / Edit profile</Select.SelectItem>
                  <Select.SelectItem key="enter_brief">Entering a brief</Select.SelectItem>
                  <Select.SelectItem key="voting">Voting page</Select.SelectItem>
                  <Select.SelectItem key="briefs">Briefs page</Select.SelectItem>
                  <Select.SelectItem key="submissions">Viewing a Submission</Select.SelectItem>
                  <Select.SelectItem key="submission_vote">
                    Voting for a Submission
                  </Select.SelectItem>
                  <Select.SelectItem key="feedback">Feedback</Select.SelectItem>
                  {isOrg && (
                    <Select.SelectItem key="create_brief">Creating a brief</Select.SelectItem>
                  )}
                </Select>
              )}

              <ControlledTextarea
                name="details"
                control={control}
                label="Please describe your problem / feedback"
                maxRows={10}
                minRows={5}
                isInvalid={!!errors.details}
                errorMessage={errors.details?.message}
              />

              {apiError.message && (
                <Input {...register("api")} defaultValue={apiError.message} className="hidden" />
              )}

              {apiError.timestamp && (
                <Input
                  {...register("timestamp")}
                  defaultValue={dayjs(apiError.timestamp).format("MMMM DD, HH:mm:ss")}
                  className="hidden"
                />
              )}
            </>
          ) : (
            <div className="text-center">
              <small>
                Thank you for your feedback.
                <br />
                We may be in touch to ask for further information.
              </small>
            </div>
          )}
        </Modal.ModalBody>

        <Modal.ModalActionsFooter
          onCancel={handleClose}
          cancelText={issueSent ? "Close" : "Cancel"}
          confirmText="Send"
          confirmProps={{
            isDisabled: isPending || issueSent,
            isLoading: isPending,
            endContent: <SendIcon className="!size-4" />,
          }}
        />
      </Form>
    </Modal>
  );
}

ReportFeedbackModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  isOrg: PropTypes.bool,
  show: PropTypes.bool,
  defaultReason: PropTypes.string,
  defaultPage: PropTypes.string,
  apiError: PropTypes.exact({
    message: PropTypes.string,
    timestamp: PropTypes.number,
  }),
};
