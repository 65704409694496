import { useEffect, useState, useContext } from "react";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import AuthContext from "features/authentication/context/AuthContext";

/**
 * This hook is used to protect an action from being called if the user is not logged in.
 * If the user is not logged in, the user will be redirected to the auth page.
 * After the user logs in, the action will automatically be called.
 *
 * It is possible to pass arguments to the action function,
 * keep in mind that the arguments must be serializable to JSON to be stored in local storage.
 *
 * If the user is logged in, the action is called without any redirection.
 *
 * @param {string} actionName - The name of the action to be protected.
 * @param {function} actionFunction - The function to be called if the user is logged in.
 * @returns {function} - A function that can be called to execute the action.
 * @example
 * const handleAction = useProtectedAction('actionName', actionFunction);
 * handleAction();
 * */
export default function useProtectedAction(actionFunction, options = {}) {
  const [loading, setLoading] = useState(false);
  const { showAuth } = useContext(AuthContext);
  const { actionName, ...authOptions } = options;
  const sessionContext = useSessionContext();

  useEffect(() => {
    if (!sessionContext.doesSessionExist) return;

    const handleAction = async () => {
      setLoading(true);
      const args = JSON.parse(localStorage.getItem("return-action-args"));
      await actionFunction(...(args ?? []));
      setLoading(false);

      localStorage.removeItem("return-action");
      localStorage.removeItem("return-action-args");
    };

    if (localStorage.getItem("return-action") === actionName) handleAction();
  }, []);

  const handleAction = async (...args) => {
    if (!sessionContext.doesSessionExist) {
      // optionally, set the return action
      if (actionName) {
        localStorage.setItem("return-action", actionName);
        localStorage.setItem("return-action-args", JSON.stringify(args));
      } else {
        localStorage.removeItem("return-action");
        localStorage.removeItem("return-action-args");
      }

      showAuth(authOptions);
      return;
    }

    await actionFunction(...args);
  };

  return [handleAction, loading];
}
