import { useContext } from "react";
import { useUpdateUser, UserContext } from "features/user";
import { Button } from "ui";
import { useForm, ControlledUsernameInput, Form, handleFormError } from "forms";
import PropTypes from "prop-types";

export default function OnboardingUsername({ onComplete }) {
  const { user } = useContext(UserContext);
  const { mutate, isPending } = useUpdateUser();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  return (
    <div data-testid="user-onboarding-username">
      <h2 className="capitalize mb-8 text-4xl">Claim Your Username</h2>

      <Form
        onSubmit={handleSubmit((formData) =>
          mutate(formData, {
            onSuccess: onComplete,
            onError: (error, data) =>
              handleFormError({ error, data, setError, errorPage: "onboarding" }),
          }),
        )}
      >
        <div>
          <small className="text-primary-400 font-roman">Email</small>
          <p>{user.email}</p>
        </div>

        <ControlledUsernameInput
          name="username"
          control={control}
          defaultValue={user.username || ""}
          label="Username"
        />

        <Button
          type="submit"
          color="primary"
          trackingName="continue onboarding"
          isDisabled={!!errors.username || isPending}
          isLoading={isPending}
          fullWidth
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}

OnboardingUsername.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
