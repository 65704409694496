import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { socialIcons, socialPrefixes } from "features/profile";
import { publicUserPropTypes, UserContext, UserBadgeList, useUserReport } from "features/user";
import { ReportModal, ErrorToast } from "features/report";
import { useProtectedAction } from "features/authentication";
import { organisationPropTypes } from "features/organisation";
import {
  Container,
  Row,
  Col,
  ShareButton,
  Shimmer,
  ModalClickContainer,
  Button,
  Dropdown,
  Popover,
  Avatar,
} from "ui";
import { tv } from "tailwind-variants";
import { twMerge } from "tailwind-merge";
import FollowersModal from "features/profile/components/modal/FollowersModal";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import api from "adapters/api";
import PropTypes from "prop-types";
import FlagIcon from "@mui/icons-material/Flag";

function ProfileStat({ name = "", value = undefined, ...props }) {
  if (value === undefined || name === "") return null;

  return (
    <div
      className="border-l-2 border-foreground pl-2"
      data-testid={`profile-banner-${name.toLowerCase()}`}
      {...props}
    >
      <span className="font-bold text-sm sm:text-base">{name}</span>
      <h2 className="mb-0">{value}</h2>
    </div>
  );
}
ProfileStat.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
};

function ProfileBannerLoading() {
  return (
    <Container className="py-12 after:pb-12 after:block">
      <Row>
        <Col xs={2} xl={1}>
          <Shimmer className="aspect-square !size-20 rounded-full" />
        </Col>

        <Col xs={12} xxl={11} className="flex justify-center flex-col my-4 2xl:mt-0">
          <Shimmer className="w-full p-6 rounded-full h-1/2" />
          <Shimmer className="w-1/2 p-2 mt-2 rounded-full h-1/2" />
        </Col>
      </Row>

      <Row>
        <Col xs={0} xxl={1} />

        <Col xs={9} className="flex">
          <Shimmer className="!size-8 p-1 rounded-full mr-4" />
          <Shimmer className="!size-8 p-1 rounded-full" />
        </Col>

        <Col xs={12} sm={3} xxl={2} className="mt-4 sm:mt-0 flex">
          <Shimmer className="w-1/2 p-6 rounded-full h-1/2 mr-4" />
          <Shimmer className="!size-12 p-1 rounded-full" />
        </Col>
      </Row>
    </Container>
  );
}

const profileBanner = tv({
  slots: {
    base: "py-12 after:pb-12 after:content-[''] after:block",
    content: "flex justify-between flex-wrap uppercase mt-4 3xl:mt-0",
    socials: "flex items-center",
    options: "flex gap-2 sm:gap-4 items-end justify-start sm:justify-end text-nowrap mt-4 sm:mt-0",
  },
});

function ProfileBanner({
  profile,
  urlPrefix,
  profileType,
  className = "",
  classNames = {},
  allowReport = true,
  entries = undefined,
  briefs = undefined,
  edit = "",
}) {
  const [following, setFollowing] = useState(profile.following);
  const [followersCount, setFollowersCount] = useState(profile.follower_count);
  const [followingCount, setFollowingCount] = useState(profile.following_count);
  const { user } = useContext(UserContext);
  const { base, content, socials, options } = profileBanner();

  const [handleFollow] = useProtectedAction(async (checked) => {
    let res;
    if (checked) res = await api.put({ url: `${urlPrefix}/${profile.id}/follow` });
    else res = await api.delete({ url: `${urlPrefix}/${profile.id}/follow` });

    if (!res.success) {
      toast(
        <ErrorToast
          errorMessage={res.errorDetails.message}
          errorProps={{
            defaultReason: "issue",
            defaultPage: "other",
            apiError: res.errorDetails,
          }}
        />,
        {
          limit: 1,
        },
      );
      return;
    }

    setFollowersCount(checked ? followersCount + 1 : followersCount - 1);
    setFollowing(checked);
  });

  return (
    <Container
      className={twMerge(base(), className, classNames.base)}
      data-slot="base"
      data-testid="profile-banner"
    >
      <Row>
        <Col xs={12} xxl={1}>
          <Avatar
            isBordered
            className="!size-20"
            src={profile.icon}
            country={profile?.country_code || ""}
            name={profile.username || profile.name}
            size="lg"
          />
        </Col>

        <Col
          className={twMerge(content(), classNames.content)}
          xs={12}
          xxl={11}
          data-slot="content"
        >
          <div className="mb-4">
            <div className="flex items-center">
              <h2 className="mb-0">{profile.username || profile.name}</h2>
              <UserBadgeList className="!size-8 ml-2" badges={profile.badges} />
            </div>

            <small className="font-bold text-primary-600 uppercase">{profileType}</small>
          </div>

          <div className="flex justify-between gap-6 sm:gap-12 mb-4">
            <ProfileStat name="Entries" value={entries} />

            <ProfileStat name="Briefs" value={briefs} />

            <ModalClickContainer
              modal={FollowersModal}
              modalProps={{
                user: profile,
                followersCount,
                followingCount,
                setFollowingCount,
                urlPrefix,
              }}
            >
              <ProfileStat name="Followers" value={followersCount} role="button" />
            </ModalClickContainer>

            <ModalClickContainer
              modal={FollowersModal}
              modalProps={{
                user: profile,
                defaultTab: "following",
                followersCount,
                followingCount,
                setFollowingCount,
                urlPrefix,
              }}
            >
              <ProfileStat name="Following" value={followingCount} role="button" />
            </ModalClickContainer>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={0} xxl={1} />

        <Col xs={9}>
          <p className="whitespace-pre-line">{profile.description}</p>
        </Col>
      </Row>

      <Row>
        <Col xs={0} xxl={1} />

        <Col xs={9} className={twMerge(socials(), classNames.socials)} data-slot="socials">
          {Object.keys(profile.metadata?.socials || []).map((social) => {
            if (!(social in socialIcons)) return null;
            const Icon = socialIcons[social];
            let url = profile.metadata.socials[social];

            if (!/(http(s?)):\/\//i.test(url)) url = `https://${url}`;

            if (socialPrefixes[social].slice(-1) !== "@") url = url.replace("@", "");

            return (
              <a
                key={social}
                href={url}
                target="__blank"
                className="bg-primary inline-block rounded-full mr-4"
              >
                <Icon className="text-primary-foreground !size-8 p-1" />
              </a>
            );
          })}
        </Col>

        <Col
          xs={12}
          sm={3}
          xxl={2}
          className={twMerge(options(), classNames.options)}
          data-slot="options"
        >
          {edit !== "" ? (
            <Button
              color="primary"
              trackingName="view edit profile"
              trackingLocation="profile"
              as={Link}
              to={edit}
            >
              Edit Profile
            </Button>
          ) : (
            <Button
              color={following ? "primary" : "success"}
              onClick={() => handleFollow(!following)}
              trackingName={following ? "unfollow" : "follow"}
              trackingLocation="profile"
            >
              {following ? "Unfollow" : "Follow"}
            </Button>
          )}

          <Popover
            placement="bottom"
            radius="full"
            classNames={{
              content: "py-2",
            }}
          >
            <Popover.PopoverTrigger>
              <Button isIconOnly color="primary">
                <ShareOutlinedIcon />
              </Button>
            </Popover.PopoverTrigger>

            <Popover.PopoverContent>
              <ShareButton color="primary" />
            </Popover.PopoverContent>
          </Popover>

          {user.id !== profile.id && allowReport && (
            <Dropdown>
              <Dropdown.DropdownTrigger>
                <Button isIconOnly variant="light">
                  <MoreHorizIcon />
                </Button>
              </Dropdown.DropdownTrigger>

              <Dropdown.DropdownMenu closeOnSelect={false}>
                <Dropdown.DropdownItem
                  key="report"
                  endContent={<FlagIcon />}
                  as={ModalClickContainer}
                  modal={ReportModal}
                  protectedAction
                  modalProps={{
                    title: "Report User",
                    mutateFn: () => useUserReport(profile.id),
                    reasons: [
                      "Advertising",
                      "Adult Content",
                      "Fraud/Scam",
                      "Harmful or illegal",
                      "Imminent physical harm",
                      "Infringes my rights",
                      "Misinformation and Disinformation",
                      "Other",
                    ],
                  }}
                >
                  Report
                </Dropdown.DropdownItem>
              </Dropdown.DropdownMenu>
            </Dropdown>
          )}
        </Col>
      </Row>
    </Container>
  );
}

ProfileBanner.propTypes = {
  profile: PropTypes.oneOfType([publicUserPropTypes, organisationPropTypes]).isRequired,
  urlPrefix: PropTypes.string.isRequired,
  profileType: PropTypes.oneOf(["Organisation", "People"]).isRequired,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    content: PropTypes.string,
    socials: PropTypes.string,
    options: PropTypes.string,
  }),
  allowReport: PropTypes.bool,
  entries: PropTypes.number,
  briefs: PropTypes.number,
  edit: PropTypes.string,
};

ProfileBanner.Loading = ProfileBannerLoading;
ProfileBanner.Organisation = function (props) {
  return <ProfileBanner urlPrefix="organisations" profileType="Organisation" {...props} />;
};
ProfileBanner.User = function (props) {
  return <ProfileBanner urlPrefix="users" profileType="People" {...props} />;
};

export default ProfileBanner;
