import { UserBadgeList } from "features/user";
import { Shimmer, Avatar } from "ui";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";
import PropTypes from "prop-types";

function UserLoading() {
  return (
    <div className={twMerge("rounded-3xl bg-inherit p-2")}>
      <div className="flex items-center">
        <Shimmer className="aspect-square !size-16 rounded-full" />

        <div className="mx-4 w-1/2">
          <Shimmer className="w-full p-2 rounded h-1/2" />
          <Shimmer className="w-1/2 p-2 mt-2 rounded h-1/2" />
        </div>
      </div>
    </div>
  );
}

const user = tv({
  slots: {
    base: "flex items-center p-2 bg-content1 gap-3",
    content: "flex items-center flex-wrap overflow-hidden",
    name: "uppercase text-lg",
    badge: "!size-5 ml-1",
    description: "font-roman text-xs",
  },
});

function User({
  name,
  badges = [],
  description = "",
  className = "",
  classNames = {},
  avatarProps = {},
  as = "div",
  ...props
}) {
  const {
    base: baseClassNames,
    content: contentClassNames,
    name: nameClassNames,
    badge: badgeClassNames,
    description: descriptionClassNames,
  } = user();
  const As = as;

  return (
    <As
      className={twMerge(baseClassNames(), className, classNames.base)}
      data-slot="base"
      {...props}
    >
      <Avatar name={name} {...avatarProps} />

      <div className="flex flex-col">
        <div className={twMerge(contentClassNames(), classNames.content)} data-slot="content">
          <span className={twMerge(nameClassNames(), classNames.name)} data-slot="name">
            {name}
          </span>
          <UserBadgeList className={twMerge(badgeClassNames(), classNames.badge)} badges={badges} />
        </div>

        {description !== "" && (
          <small
            className={twMerge(descriptionClassNames(), classNames.description)}
            data-slot="description"
          >
            {description}
          </small>
        )}
      </div>
    </As>
  );
}
User.propTypes = {
  name: PropTypes.string.isRequired,
  badges: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    content: PropTypes.string,
    name: PropTypes.string,
    badge: PropTypes.string,
    description: PropTypes.string,
  }),
  avatarProps: PropTypes.shape({}),
  as: PropTypes.elementType,
};

User.Loading = UserLoading;
export default User;
