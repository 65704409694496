import { useParams } from "react-router-dom";
import { Container, CallToAction, DateProgressBar } from "ui";
import routes from "default/routes";
import { PageLayout } from "features/navigation";
import { useBrief } from "features/brief";

export default function EnterBriefComplete() {
  const { id } = useParams();
  const { data, isLoading } = useBrief(id);
  const brief = data || {};

  return (
    <PageLayout isLoading={isLoading}>
      <Container className="mb-20" data-testid="published-submission">
        <div className="text-center mb-8">
          <h2 className="uppercase mt-8 mb-8">Well Done! ✨</h2>

          <div className="flex w-full justify-around md:py-6 py-4 flex-wrap sm:flex-nowrap">
            <DateProgressBar
              startDate={brief.start_time}
              endDate={brief.submission_deadline}
              title="Submit Your Work"
            />
            <DateProgressBar
              startDate={brief.submission_deadline}
              endDate={brief.voting_deadline}
              title="Voting"
            />
            <DateProgressBar
              startDate={brief.voting_deadline}
              endDate={brief.winners_selected}
              title="Winner Announced"
            />
          </div>
        </div>

        <Container className="bg-content4 text-primary-foreground rounded-3xl mb-4 py-4 md:py-12">
          <CallToAction
            title={
              <>
                Share your
                <br className="hidden lg:block" /> opinion
              </>
            }
            description="Feedback is a way to help out your fellow designers. Check the Feedback Hub often to see who has given you feedback on your submission, or to give feedback to a fellow designer!"
            cta="Explore Feedback"
            to={routes.feedbackHub}
            trackingName="view feedback"
            className="bg-content1"
          />
        </Container>
      </Container>
    </PageLayout>
  );
}
