import { useState, useLayoutEffect } from "react";
import { twMerge } from "tailwind-merge";
import Button from "ui/buttons/Button";
import ImageZoom from "react-medium-image-zoom";
import PropTypes from "prop-types";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

const defaultTransform = {
  transform: "scale(1.5)",
  transformOrigin: "0% 50%",
};

function CustomZoom({ img, buttonUnzoom, modalState, onUnzoom }) {
  const [zoomIn, setZoomIn] = useState(false);
  const [transformStyle, setTransformStyle] = useState(defaultTransform);

  const resetTransform = () => {
    setTransformStyle(defaultTransform);
  };

  const applyTransform = (clientX, clientY, element) => {
    const { left, top, width, height } = element.getBoundingClientRect();
    const posX = clientX - left;
    const posY = clientY - top;
    const percentX = (posX / width) * 100;
    const percentY = (posY / height) * 100;

    setTransformStyle({
      transform: "scale(1.5)",
      transformOrigin: `${percentX}% ${percentY}%`,
    });
  };

  const handleMouseMove = (event) => {
    applyTransform(event.clientX, event.clientY, event.currentTarget);
  };

  useLayoutEffect(() => {
    if (modalState === "UNLOADING") {
      setZoomIn(false);
      resetTransform();
    }
  }, [modalState]);

  return (
    <div
      className="w-screen h-screen touch-device:overflow-x-scroll overflow-hidden"
      onClick={onUnzoom}
      role="presentation"
    >
      <div
        className={twMerge(
          "relative cursor-zoom-in z-50 transform transition duration-y touch-device:pointer-events-none",
          zoomIn && "cursor-zoom-out w-full h-full",
        )}
        onClick={(e) => {
          e.stopPropagation();
          setZoomIn(!zoomIn);
        }}
        onMouseMove={handleMouseMove}
        style={{
          ...(zoomIn && transformStyle),
        }}
        role="presentation"
      >
        {img}
      </div>

      <Button
        color="primary"
        isIconOnly
        className="fixed top-5 right-16 mr-3 z-50 hover:bg-content1 hover:text-foreground hover:!opacity-100"
        onClick={() => setZoomIn(!zoomIn)}
      >
        {!zoomIn ? <ZoomInIcon /> : <ZoomOutIcon />}
      </Button>
      {buttonUnzoom}
    </div>
  );
}
CustomZoom.propTypes = {
  img: PropTypes.elementType.isRequired,
  buttonUnzoom: PropTypes.elementType.isRequired,
  modalState: PropTypes.string.isRequired,
  onUnzoom: PropTypes.func.isRequired,
};

export default function Zoom({ children, ...props }) {
  return (
    <ImageZoom ZoomContent={CustomZoom} swipeToUnzoomThreshold={50} {...props}>
      {children}
    </ImageZoom>
  );
}
Zoom.propTypes = {
  children: PropTypes.node.isRequired,
};
