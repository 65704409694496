import { SubmissionCard, submissionListPropTypes } from "features/submission";
import PropTypes from "prop-types";
import ScrollingRow from "./ScrollingRow";

function ScrollingSubmissions({ submissions, ...rowProps }) {
  return (
    <ScrollingRow {...rowProps}>
      {submissions.map((submission) => (
        <SubmissionCard
          entry={submission}
          classNames={{
            base: "min-w-60 max-w-60",
            title: "hidden",
          }}
          hideOptions
        />
      ))}
    </ScrollingRow>
  );
}
ScrollingSubmissions.propTypes = {
  submissions: PropTypes.arrayOf(submissionListPropTypes).isRequired,
};

export default function LandingSubmissions({ submissions, isPaused }) {
  return (
    <>
      <div className="z-20 px-4 text-center mb-48 flex flex-col gap-4 before:z-[-1] before:fixed before:top-0 before:left-0 before:size-full before:bg-black/40">
        <h2 className="max-w-[700px]">Bring your ideas to life</h2>
        <p>
          Submit your design, showcase your talent, and connect with a community other creatives.
        </p>
      </div>

      <div className="absolute bottom-0 flex-col pointer-events-none gap-4 z-0 hidden sm:flex">
        <div className="flex gap-4 -ml-[25%]">
          <ScrollingSubmissions submissions={submissions.slice(0, 9)} isPaused={isPaused} />
          <ScrollingSubmissions
            submissions={submissions.slice(0, 9)}
            className="mr-4"
            isPaused={isPaused}
          />
        </div>
        <div className="flex gap-4 ml-[25%]">
          <ScrollingSubmissions
            submissions={submissions.slice(10, 19)}
            direction="backward"
            isPaused={isPaused}
          />
          <ScrollingSubmissions
            submissions={submissions.slice(10, 19)}
            direction="backward"
            isPaused={isPaused}
          />
        </div>
      </div>

      <div className="absolute top-0 flex pointer-events-none gap-4 z-0 sm:hidden">
        <div className="flex flex-col gap-4">
          <ScrollingSubmissions
            submissions={submissions.slice(0, 9)}
            direction="upward"
            className="flex-col"
            isPaused={isPaused}
          />
          <ScrollingSubmissions
            submissions={submissions.slice(0, 9)}
            direction="upward"
            className="flex-col"
            isPaused={isPaused}
          />
        </div>

        <div className="flex flex-col top-0">
          <ScrollingSubmissions
            submissions={submissions.slice(10, 19)}
            direction="downward"
            className="flex-col -mt-[1000%]"
            isPaused={isPaused}
          />
          <ScrollingSubmissions
            submissions={submissions.slice(10, 19)}
            direction="downward"
            className="flex-col mt-4"
            isPaused={isPaused}
          />
        </div>
      </div>
    </>
  );
}
LandingSubmissions.propTypes = {
  submissions: PropTypes.arrayOf(submissionListPropTypes).isRequired,
  isPaused: PropTypes.bool.isRequired,
};
