const supertokensStyles = `
[data-supertokens~="container"] {
    font-family: 'Helvetica Neue LT Pro Bold Condensed', sans-serif;
    box-shadow: none;
    display: flex;
    align-items: center;
    margin: auto;
    width: auto;
    border-radius: 1.5rem;
}

[data-supertokens~="headerTitle"]::before {
    content: '';
    width: 50px;
    height: 50px;
    display: block;
    margin: auto;
    margin-bottom: 16px;
    background-image: url('https://platform-2scmbsejnq-ez.a.run.app/static/media/prospect100icon.29fb2fdd8fd24d5a42e6.png');
    background-size: 100%;
}

[data-supertokens~="superTokensBranding"] {
    display: none;
}

[data-supertokens~="button"]:not([data-supertokens~="providerButton"]) {
    background-color: #000;
    border: none;
    height: auto;
    font-size: 16px;
    text-transform: capitalize;
    padding-top: 16px;
    padding-bottom: 16px;
}   

[data-supertokens~="providerButton"] {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}

[data-supertokens~="inputWrapper"] {
    height: auto;
    background-color: transparent;
}

[data-supertokens~="input"] {
    letter-spacing: normal;
    font-size: 16px;
    height: auto;
    padding-top: 16px;
    padding-bottom: 16px;
}

[data-supertokens~="label"] {
    font-size: 16px;
}                
`;

export default supertokensStyles;
