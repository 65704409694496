import { toast } from "react-toastify";
import { ErrorToast } from "features/report";

const handleFormError = ({ error, data, setError, errorPage = "" } = {}) => {
  if (!error || !data) return;
  const apiErrors = Object.entries(error.parseError(data));

  if (setError && apiErrors.length) {
    apiErrors.forEach(([name, message]) => {
      setError(name, { message, type: "custom" });
    });
  } else {
    toast(
      <ErrorToast
        errorMessage={error.details.message}
        errorProps={{
          defaultReason: "issue",
          defaultPage: errorPage,
          apiError: error.details,
        }}
      />,
      { limit: 1 },
    );
  }
};

export { handleFormError };
