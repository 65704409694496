import { DateInput } from "@nextui-org/react";
import { twMerge } from "tailwind-merge";
import styled from "styled-components";

const StyledDateInput = styled(DateInput).attrs((props) => ({
  radius: props.radius ?? "lg",
  classNames: {
    inputWrapper: twMerge("px-4", props.classNames?.inputWrapper || ""),
  },
}))``;

export default StyledDateInput;
