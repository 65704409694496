import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BriefCard, useBriefs } from "features/brief";
import { ProfileBanner } from "features/profile";
import { NotFound } from "features/report";
import { PageLayout } from "features/navigation";
import { Container, CardGrid, AutoScrollLoader } from "ui";
import useKeyGen from "hooks/useKeyGen";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import api from "adapters/api";

export default function Organisation() {
  const { id } = useParams();
  const [orgLoading, setOrgLoading] = useState(true);
  const [organisation, setOrganisation] = useState({});
  const keyGen = useKeyGen();
  const briefs = useBriefs({ org_id: id, status: null });

  useEffect(() => {
    const getOrganisation = async () => {
      setOrgLoading(true);

      const res = await api.get({ url: `organisations/${id}` });

      if (res.success) setOrganisation(res.data);

      setOrgLoading(false);
    };

    getOrganisation();
  }, [id]);

  return (
    <PageLayout pageName="Organisation" showFooter={!briefs.hasNextPage}>
      {Object.keys(organisation).length > 0 || orgLoading || briefs.isLoading ? (
        <>
          <div className="bg-content2 rounded-t-3xl">
            {!orgLoading ? (
              <ProfileBanner.Organisation
                profile={organisation}
                briefs={briefs.total}
                key={keyGen.getKey(organisation)}
                classNames={{
                  content: "normal-case",
                }}
              />
            ) : (
              <ProfileBanner.Loading />
            )}
          </div>

          <div className="bg-background -mt-12 rounded-t-3xl">
            <Container className="after:pb-12 after:block py-6">
              <CardGrid entries={briefs.all} card={BriefCard} sizing={{ md: 6, xl: 4 }} />

              <AutoScrollLoader
                fetchNextPage={briefs.fetchNextPage}
                hasNextPage={briefs.hasNextPage}
                isLoading={briefs.isLoading || briefs.isFetchingNextPage}
                error={briefs.error}
                empty={!briefs.all.length}
              >
                <ImageNotSupportedOutlinedIcon className="!size-12 mb-4 text-default-300 !block mx-auto" />
                <span className="font-roman text-default-300">No Briefs Yet</span>
              </AutoScrollLoader>
            </Container>
          </div>
        </>
      ) : (
        <NotFound
          text={"Bummer. \n The requests organisation could not be found or does not exist."}
          issuePage="profile"
        />
      )}
    </PageLayout>
  );
}
