import { useParams, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

import { Modal, Button } from "ui";
import useSubmissionWithActions from "features/submission/hooks/useSubmissionWithActions";
import SubmissionBody from "features/submission/components/modal/SubmissionModal/SubmissionBody";
import SubmissionHeader from "features/submission/components/modal/SubmissionModal/SubmissionHeader";
import CloseIcon from "@mui/icons-material/Close";

export default function SubmissionModal({ showVoteButton = true, isOrg = false }) {
  const { submissionId } = useParams();
  const {
    submission,
    isLoading: isSubmissionLoading,
    handleSelectWinner,
    handleDeselectWinner,
  } = useSubmissionWithActions(submissionId);
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleCloseSubmissionModal = () => {
    let [targetURL] = window.location.pathname.split("submission");
    if (isOrg) [targetURL] = window.location.pathname.split("org")[1].split("submission");

    return navigate(`${state?.referrer || targetURL}?${searchParams.toString()}`, {
      state: {
        scrollToTop: false,
      },
    });
  };

  return (
    <Modal
      defaultOpen
      onClose={handleCloseSubmissionModal}
      data-testid="submission-modal"
      scrollBehavior="outside"
      hideCloseButton
      classNames={{
        base: "bg-transparent border-none shadow-none max-w-6xl w-full md:w-4/5 !m-0 md:!-mt-8",
        backdrop: "bg-overlay/80",
      }}
    >
      <Button
        isIconOnly
        className="bg-content1 w-10 h-10 min-w-0 absolute md:sticky self-end right-0 top-8 mr-4 md:-mr-14"
        onClick={handleCloseSubmissionModal}
      >
        <CloseIcon className="p-1" />
      </Button>

      {!isSubmissionLoading && submission ? (
        <>
          <SubmissionHeader
            submission={submission}
            handleSelectWinner={handleSelectWinner}
            handleDeselectWinner={handleDeselectWinner}
            allowSelectWinner={isOrg}
            allowSelectSpotlight={
              isOrg &&
              submission.brief?.type !== "private" &&
              submission.brief?.status !== "submitting"
            }
            showVoteButton={!isOrg && showVoteButton}
          />

          <SubmissionBody
            submission={submission}
            allowSelectWinner={isOrg}
            showVoteButton={!isOrg && showVoteButton}
            handleCloseSubmissionModal={handleCloseSubmissionModal}
          />
        </>
      ) : (
        <>
          <SubmissionHeader.Loading />
          <SubmissionBody.Loading />
        </>
      )}
    </Modal>
  );
}

SubmissionModal.propTypes = {
  submission: PropTypes.shape({}).isRequired,
  showVoteButton: PropTypes.bool,
  isOrg: PropTypes.bool,
};
