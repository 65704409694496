import { VisuallyHidden, useSwitch } from "@nextui-org/react";
import LanguageIcon from "@mui/icons-material/Language";

export default function CountrySwitch(props) {
  const { Component, slots, getBaseProps, getInputProps, getWrapperProps } = useSwitch(props);

  return (
    <div className="flex items-center justify-center h-12 gap-2 bg-default-100 p-1.5 rounded-2xl aspect-square">
      <Component {...getBaseProps()} className={slots.base({ class: ["w-full h-full"] })}>
        <VisuallyHidden>
          <input {...getInputProps()} />
        </VisuallyHidden>
        <div
          {...getWrapperProps()}
          className={slots.wrapper({
            class: [
              "w-full h-full aspect-square",
              "flex items-center justify-center",
              "rounded-xl bg-default-100 m-0 p-0",
            ],
          })}
        >
          <LanguageIcon className="!size-6 md:!size-8 p-0.5" />
        </div>
      </Component>
    </div>
  );
}
