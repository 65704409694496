import { useEffect, useState } from "react";
import { useSubmissions } from "features/submission";
import { PageLayout } from "features/navigation";
import { useBrief, useEnterBriefMutations, PrivateBriefNDAModal } from "features/brief";
import { motion } from "framer-motion";
import { Container, Button, Tooltip } from "ui";
import { Link, useParams, useNavigate, generatePath, Navigate } from "react-router-dom";
import { useLeaderboard } from "features/leaderboard";
import { useDisclosure } from "@nextui-org/react";
import { useProtectedAction } from "features/authentication";
import { handleFormError } from "forms";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import routes from "default/routes";
import PropTypes from "prop-types";
import LandingCountdown from "./LandingCountdown";
import LandingSubmissions from "./LandingSubmissions";
import LandingUsers from "./LandingUsers";
import LandingFeedback from "./LandingFeedback";
import LandingIncentives from "./LandingIncentives";

const PAGESPEED = 60;
const TOTALSLIDES = 5;

function ProgressBar({ progress }) {
  return (
    <motion.div
      className="h-4 sm:h-5 bg-default-foreground rounded"
      initial={{ width: 0 }}
      animate={{ width: `${progress}%` }}
      transition={{ duration: 0.1 }}
    />
  );
}
ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

function AutoProgress({ paused, currentSlide, setCurrentSlide }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          setCurrentSlide((prev) => (prev + 1) % TOTALSLIDES);
          return 0;
        }
        return oldProgress + 1;
      });
    }, PAGESPEED); // 50ms * 100 = 5000ms = 5s

    if (paused) clearTimeout(timer);

    return () => clearInterval(timer);
  }, [paused]);

  const handleNext = () => {
    if (paused) return;
    setCurrentSlide((prev) => (prev + 1) % TOTALSLIDES);
    setProgress(0);
  };

  const handlePrev = () => {
    if (paused) return;
    setCurrentSlide((prev) => (prev - 1 + TOTALSLIDES) % TOTALSLIDES);
    setProgress(0);
  };

  return (
    <>
      {currentSlide > 0 && (
        <>
          <Button
            isIconOnly
            color="primary"
            className="fixed left-4 top-1/2 transform -translate-y-1/2 z-20 touch-device:hidden"
            onClick={handlePrev}
            trackingName="landing prev"
            trackingLocation="desktop"
          >
            <ChevronLeft />
          </Button>

          <Button
            className="fixed top-0 left-0 h-full w-2/5 hidden touch-device:block bg-transparent"
            radius="none"
            onClick={handlePrev}
            disableAnimation
            trackingName="landing prev"
            trackingLocation="mobile"
          />
        </>
      )}

      <Button
        isIconOnly
        color="primary"
        className="fixed right-4 top-1/2 transform -translate-y-1/2 z-20 touch-device:hidden"
        onClick={handleNext}
        trackingName="landing next"
        trackingLocation="desktop"
      >
        <ChevronRight />
      </Button>

      <Button
        className="fixed top-0 right-0 h-full w-2/5 hidden touch-device:block bg-transparent"
        onClick={handleNext}
        radius="none"
        disableAnimation
        trackingName="landing next"
        trackingLocation="mobile"
      />

      <div className="flex items-center justify-between h-min w-full py-6 gap-2 z-30">
        <div className="flex gap-2 sm:gap-3 w-full h-min">
          {[...Array(TOTALSLIDES)].map((_, index) => (
            <div className="flex-1 bg-default overflow-hidden p-1 rounded max-w-24">
              <ProgressBar
                // eslint-disable-next-line no-nested-ternary
                progress={index === currentSlide ? progress : index < currentSlide ? 100 : 0}
              />
            </div>
          ))}
        </div>
        <Tooltip content="Not Interested?">
          <Button
            isIconOnly
            variant="light"
            as={Link}
            to={routes.briefs}
            trackingName="landing not interested"
          >
            <CloseIcon />
          </Button>
        </Tooltip>
      </div>
    </>
  );
}
AutoProgress.propTypes = {
  paused: PropTypes.bool.isRequired,
  currentSlide: PropTypes.number.isRequired,
  setCurrentSlide: PropTypes.func.isRequired,
};

export default function BriefLanding() {
  const { id } = useParams();
  const { data, isLoading } = useBrief(id);
  const { all: submissions, isLoading: submissionsLoading } = useSubmissions({
    published: true,
    page_size: 20,
  });
  const { all: users, isLoading: usersLoading } = useLeaderboard("points", {
    page_size: 20,
  });
  const {
    enterBrief: { mutate, isPending: enterBriefLoading },
  } = useEnterBriefMutations();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [paused, setPaused] = useState(false);
  const { isOpen: isNDAModalOpen, onOpen: openNDAModal, onClose: closeNDAModal } = useDisclosure();
  const navigate = useNavigate();
  const brief = data ?? {};

  const [enterBrief] = useProtectedAction(
    (briefUrlId, briefId) => {
      mutate(
        { id: briefUrlId },
        {
          onSuccess: () => {
            navigate(generatePath(routes.brief, { id: briefId }), {
              replace: true,
            });
          },
          onError: (error, errorData) =>
            handleFormError({ error, data: errorData, errorPage: "enter_brief" }),
        },
      );
    },
    {
      actionName: "enterBrief",
      redirectToPath: generatePath(routes.briefLanding, { id }),
    },
  );

  const handleEnterOrShowNDA = () => {
    if (brief.type === "private") {
      openNDAModal();
      return;
    }
    enterBrief(id, brief.id);
  };

  if (
    process.env.REACT_APP_ENABLE_BRIEF_LANDING === "FALSE" ||
    (Object.keys(brief).length > 0 &&
      (brief.entered || ["voting", "judging", "completed"].includes(brief.status)))
  )
    return <Navigate to={generatePath(routes.brief, { id })} replace />;

  return (
    <PageLayout
      pageName={["Brief", "Landing"]}
      isLoading={isLoading || submissionsLoading || usersLoading}
      showNav={false}
      showFooter={false}
      className="dark bg-background text-foreground contain-paint h-dvh" // Always a dark mode page
    >
      {Object.keys(brief).length > 0 && (
        <>
          <Container
            className="z-20 h-full"
            onTouchStart={() => setPaused(true)}
            onTouchEnd={() => setPaused(false)}
            data-testid="brief-landing"
          >
            <AutoProgress {...{ currentSlide, setCurrentSlide, paused }} />
          </Container>

          <div className="h-full w-full absolute top-0 z-10">
            <motion.div
              key={currentSlide}
              initial={{ opacity: 0, transform: "translate(0, 20px)" }}
              animate={{ opacity: 1, transform: "translate(0, 0)" }}
              exit={{ opacity: 0, transform: "translate(0, -20px)" }}
              transition={{ duration: 0.5 }}
              className="flex flex-col items-center justify-center h-full w-full relative"
            >
              {currentSlide === 0 && <LandingCountdown brief={brief} />}

              {currentSlide === 1 && (
                <LandingSubmissions submissions={submissions} isPaused={paused} />
              )}

              {currentSlide === 2 && <LandingUsers users={users} isPaused={paused} />}

              {currentSlide === 3 && <LandingFeedback />}

              {currentSlide === 4 && <LandingIncentives users={users} />}
            </motion.div>
          </div>
        </>
      )}

      <PrivateBriefNDAModal
        brief={brief}
        onConfirm={() => enterBrief(id, brief.id)}
        onHide={closeNDAModal}
        show={isNDAModalOpen}
        isLoading={enterBriefLoading}
      />

      <div className="fixed bottom-16 w-full flex justify-center z-20">
        <Button
          size="lg"
          radius="md"
          className="bg-default-900 text-default-50 min-w-48"
          onClick={handleEnterOrShowNDA}
          isLoading={enterBriefLoading}
          data-testid="enter-brief"
          trackingName="enter brief"
        >
          View Brief
        </Button>
      </div>
    </PageLayout>
  );
}
