import { useContext } from "react";
import { UserContext } from "features/user";
import { useSubmission } from "features/submission";
import { Modal, Avatar } from "ui";
import { Form, Textarea, useForm, handleFormError } from "forms";
import { useFeedbackMutations } from "features/feedback/api";
import feedbackPropTypes from "features/feedback/feedbackSchema";
import SendIcon from "@mui/icons-material/Send";
import PropTypes from "prop-types";

export default function FeedbackPromptModal({ show, submissionId, onHide, request = undefined }) {
  const { data: submission, isLoading } = useSubmission(submissionId);
  const {
    createFeedback: { mutate, isPending },
  } = useFeedbackMutations(submission?.id);
  const { user } = useContext(UserContext);
  const {
    register,
    watch,
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  if (isLoading || Object.keys(submission).length === 0) return null;

  const handleClose = () => {
    reset();
    onHide();
  };

  return (
    <Modal isOpen={show} onClose={onHide} data-testid="feedback-prompt-modal">
      <Modal.ModalBody className="p-6">
        <div className="flex justify-center">
          <Avatar
            src={submission.user?.icon}
            name={submission.user.username}
            size="lg"
            country={submission.user?.country_code}
            className="!size-20"
          />
        </div>

        <section className="flex flex-col my-4 text-center justify-center">
          <span className="text-3xl">
            Hi <span className="capitalize">{user.given_name || ""}</span>, thanks for your vote!
          </span>
          <span className="uppercase text-primary-600">One more step</span>
        </section>

        <Form
          onSubmit={handleSubmit(({ feedback }) =>
            mutate(
              { content: feedback, ...(request?.id && { parent: request?.id }) },
              {
                onSuccess: handleClose,
                onError: (error, data) =>
                  handleFormError({ error, data, setError, errorPage: "voting" }),
              },
            ),
          )}
        >
          <Textarea
            {...register("feedback", {
              maxLength: {
                value: 600,
                message: "Feedback cannot be longer than 600 characters",
              },
            })}
            maxLength={600}
            minRows={5}
            maxRows={10}
            placeholder={`What did you love most about ${submission?.user.username.toUpperCase()}'s design? Help them out by leaving some feedback (min 10 characters).`}
            isInvalid={!!errors.feedback}
            errorMessage={errors.feedback?.message}
          />

          <Modal.ModalActionsFooter
            onCancel={handleClose}
            cancelText="Skip"
            confirmText="Send Feedback"
            cancelProps={{
              variant: "light",
            }}
            confirmProps={{
              endContent: <SendIcon />,
              isDisabled: !!errors.feedback || !watch("feedback") || watch("feedback")?.length < 10,
              isLoading: isPending,
            }}
          />
        </Form>
      </Modal.ModalBody>
    </Modal>
  );
}
FeedbackPromptModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  request: feedbackPropTypes,
  submissionId: PropTypes.number.isRequired,
};
