import { useContext } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { submissionListPropTypes } from "features/submission/schemas/submissionSchema";
import { UserContext } from "features/user";
import { ConfirmationModal, ModalClickContainer, Dropdown, Button } from "ui";
import routes from "default/routes";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CollectionsIcon from "@mui/icons-material/Collections";
import useSubmissionDelete from "features/submission/hooks/useSubmissionDelete";

export default function SubmissionOptionsDropdown({ submission, hideOptions = false }) {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const handleDeleteSubmission = useSubmissionDelete();
  const navigate = useNavigate();

  if (user.id !== submission.user.id || hideOptions) return null;

  return (
    <Dropdown
      style={{
        zIndex: 10,
      }}
      data-testid="submission-options-dropdown"
    >
      <Dropdown.DropdownTrigger>
        <Button
          isIconOnly
          variant="light"
          className="bg-content1 shadow-md"
          data-testid="submission-options-button"
        >
          <SettingsOutlinedIcon />
        </Button>
      </Dropdown.DropdownTrigger>

      <Dropdown.DropdownMenu closeOnSelect={false} onClick={(e) => e.stopPropagation()}>
        {submission.can_edit && (
          <Dropdown.DropdownItem
            key="edit"
            endContent={<EditIcon />}
            data-testid="submission-options-edit"
            onClick={() =>
              navigate(
                `${generatePath(routes.enterBrief, { id: submission.brief.id })}?submission=${submission.id}`,
              )
            }
          >
            Edit
          </Dropdown.DropdownItem>
        )}

        {location.pathname.includes("user") && (
          <Dropdown.DropdownItem
            key="workspace"
            endContent={<CollectionsIcon />}
            data-testid="submission-options-workspace"
            onClick={() =>
              navigate(`${generatePath(routes.brief, { id: submission.brief.id })}?tab=workspace`)
            }
          >
            View in workspace
          </Dropdown.DropdownItem>
        )}

        <Dropdown.DropdownItem
          key="delete"
          endContent={<DeleteIcon />}
          color="danger"
          className="text-danger"
          as={ModalClickContainer}
          modal={ConfirmationModal}
          modalProps={{
            promptText: (
              <>
                Are you sure you want to delete this submission?
                <br />
                Please not that this submission will be automatically pulled out from any briefs
                (active or closed) to which this was submitted and cannot be restored.
                <br />
                <b className="text-xs uppercase">
                  Submissions that won a brief cannot be deleted from the platform.
                </b>
              </>
            ),
            action: () => handleDeleteSubmission(submission.id),
            cancelProps: {
              color: "primary",
              variant: "solid",
            },
            confirmProps: {
              color: "danger",
              variant: "flat",
            },
          }}
          data-testid="submission-options-delete"
        >
          Delete
        </Dropdown.DropdownItem>
      </Dropdown.DropdownMenu>
    </Dropdown>
  );
}
SubmissionOptionsDropdown.propTypes = {
  submission: submissionListPropTypes.isRequired,
  hideOptions: PropTypes.bool,
};
