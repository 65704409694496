import { useCallback } from "react";
import { tv } from "tailwind-variants";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "ui/buttons/IconButton";

function CompletedStep({ step, onClick = undefined }) {
  const stepForButton = useCallback(
    ({ className: stepClassName }) => (
      <div className={`min-w-full min-h-full ${stepClassName}`}>{step}</div>
    ),
    [step],
  );

  if (!onClick) return <CheckIcon />;

  return (
    <IconButton
      hoverColor
      variant="white"
      className="rounded-full p-1"
      iconClassName="flex items-center justify-center p-2"
      hoverPulse={false}
      icon={[CheckIcon, stepForButton]}
      direction="prev"
      onClick={onClick}
      aria-label={`step-${step}`}
    />
  );
}
CompletedStep.propTypes = {
  step: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

function CurrentStep({ currentStep, step, onClick = undefined }) {
  if (!onClick || currentStep !== step) return step;

  return (
    <div
      onClick={onClick}
      role="button"
      tabIndex={0}
      className="w-full h-full flex items-center justify-center"
      aria-label={`step-${step}`}
    >
      {step}
    </div>
  );
}
CurrentStep.propTypes = {
  currentStep: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

const progressStep = tv({
  slots: {
    base: "flex items-center flex-col w-full",
    wrapper: "flex items-center justify-center w-full relative overflow-hidden mb-2",
    connector: "p-1 absolute",
    step: "rounded-full !size-10 md:!size-12 flex items-center justify-center old-transition-slide-arrow",
    title: "uppercase font-bold text-2xs sm:text-sm md:text-lg text-center",
  },
  variants: {
    position: {
      first: {
        connector: "w-1/2 left-1/2",
      },
      last: {
        connector: "w-1/2 left-0",
      },
      default: {
        connector: "w-full left-0",
      },
    },
    color: {
      default: {
        connector: "bg-default-300 text-primary-foreground",
        step: "bg-default-300 text-primary-foreground",
      },
      primary: {
        connector: "bg-primary-700 text-primary-foreground",
        step: "bg-primary-700 text-primary-foreground",
      },
    },
  },
});

export default function ProgressStep({
  title,
  step,
  currentStep,
  className = "",
  classNames = {},
  color = "default",
  position = "default",
  onClick = undefined,
}) {
  const {
    connector,
    step: stepClassName,
    title: titleClassName,
    base,
    wrapper,
  } = progressStep({ position, color });
  const completed = currentStep > step;

  return (
    <div className={twMerge(base(), className, classNames.base)}>
      <div className={twMerge(wrapper(), classNames.wrapper)}>
        <span
          className={twMerge(
            connector(),
            currentStep - 1 === step && "rounded-e-full",
            completed && "bg-success text-primary-foreground",
            classNames.connector,
          )}
          data-slot="connector"
        />

        <div className="px-2 z-10">
          <span
            className={twMerge(
              stepClassName(),
              currentStep === step && "bg-white text-black border-2 border-black",
              completed && "bg-success text-primary-foreground",
              classNames.step,
            )}
            data-slot="base"
          >
            {completed ? (
              <CompletedStep step={step} onClick={onClick} />
            ) : (
              <CurrentStep currentStep={currentStep} step={step} onClick={onClick} />
            )}
          </span>
        </div>
      </div>
      <span className={twMerge(titleClassName(), classNames.title)} data-slot="title">
        {title}
      </span>
    </div>
  );
}
ProgressStep.propTypes = {
  title: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  className: PropTypes.string,
  classNames: PropTypes.oneOf(["base", "wrapper", "connector", "step", "title"]),
  color: PropTypes.oneOf(["default", "primary"]),
  position: PropTypes.oneOf(["default", "first", "last"]),
  onClick: PropTypes.func,
};
