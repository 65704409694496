import { useNavigate, generatePath } from "react-router-dom";
import { useMemo } from "react";
import { ModalClickContainer, Avatar, Chip, Card, Image, Tooltip } from "ui";
import { briefListPropTypes } from "features/brief/schemas/briefSchema";
import { briefStatusContent } from "features/brief/data/briefData";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import BriefNotInterestedModal from "features/brief/components/modal/BriefNotInterestedModal";
import CloseIcon from "@mui/icons-material/Close";
import Mixpanel from "adapters/mixpanel";
import routes from "default/routes";

function BriefStatusBadge({ brief }) {
  const countdown = useMemo(() => {
    const { reminder } = briefStatusContent(brief)[brief.status];
    return reminder;
  }, [brief]);

  if (brief.status === "completed") {
    return (
      <Chip
        className={twMerge(
          "bg-content1 uppercase",
          brief.submitted && "bg-gold text-primary-foreground old-shine-horizontal",
        )}
        radius="sm"
      >
        {brief.submitted ? "Completed" : "Submit your design!"}
      </Chip>
    );
  }
  if (countdown) {
    return (
      <Chip radius="sm" color="danger" className="uppercase old-shake">
        {countdown}
      </Chip>
    );
  }
  if (brief.status !== "scheduled" || brief.status !== "completed") {
    return (
      <Chip color="danger" radius="sm" className="uppercase old-pulse-shadow">
        Live
      </Chip>
    );
  }
}
BriefStatusBadge.propTypes = {
  brief: briefListPropTypes.isRequired,
};

export default function SmallBriefCard({
  entry: brief = {},
  onClick = undefined,
  trackingLocation = "",
}) {
  const navigate = useNavigate();
  const navigateOnClick = () => {
    if (onClick) {
      onClick(brief);
      return;
    }
    navigate(generatePath(routes.brief, { id: brief.id }));
  };

  const handleTrackOnClick = () => {
    Mixpanel.trackButton("Card", "view brief", trackingLocation, {
      "Card Type": brief.type,
    });
    navigateOnClick();
  };

  return (
    <Card className="border-none w-64 min-w-56 aspect-4/3" shadow="none" isPressable>
      <Image className="w-full h-full object-cover z-0" src={brief.thumbnail} />

      <Card.CardBody
        className="z-0 absolute flex flex-col justify-between top-0 w-full h-full group overflow-hidden after:bg-gradient-to-b after:from-black/50 after:size-full after:absolute after:top-0 after:left-0 after:opacity-0 hover:after:opacity-100 hover:after:transition-all hover:after:duration-300"
        onClick={handleTrackOnClick}
      >
        <div className="flex justify-between items-center z-10">
          <Avatar src={brief.organisation.icon} name={brief.organisation.name} />

          {brief.user_submission_count > 0 ? (
            <Chip radius="sm" color="primary" className="bg-opacity-75 uppercase">
              {`${brief.user_submission_count} ${brief.user_submission_count === 1 ? "Submission" : "Submissions"}`}
            </Chip>
          ) : (
            <div onClick={(e) => e.stopPropagation()} role="presentation">
              <ModalClickContainer
                modal={BriefNotInterestedModal}
                modalProps={{
                  brief,
                }}
              >
                <Tooltip content="Not Interested?">
                  <CloseIcon className="!size-9 bg-content2 text-foreground p-2 rounded-full opacity-100 lg:opacity-0 group-hover:opacity-100 group-hover:transition-all group-hover:duration-300" />
                </Tooltip>
              </ModalClickContainer>
            </div>
          )}
        </div>

        <div className="ml-auto">
          <BriefStatusBadge brief={brief} />
        </div>
      </Card.CardBody>
    </Card>
  );
}
SmallBriefCard.propTypes = {
  entry: briefListPropTypes,
  onClick: PropTypes.func,
  trackingLocation: PropTypes.string,
};
