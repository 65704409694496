import { useContext } from "react";
import { UserContext } from "features/user";
import { AuthContext } from "features/authentication";
import { generatePath, useLocation, Link } from "react-router-dom";
import { css } from "aphrodite";
import { Button, ThemeContext, User } from "ui";
import NavigationConfigContext from "features/navigation/context/NavigationConfigContext";
import animationStyles from "ui/animations";
import routes from "default/routes";
import PropTypes from "prop-types";
import CompleteOnboarding from "default/pages/UserProfile/CompleteOnboarding";

export default function OffcanvasAccount({ collapse, onClose, offcanvas = [] }) {
  const { user } = useContext(UserContext);
  const { logout } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const { config } = useContext(NavigationConfigContext);
  const location = useLocation().pathname;

  return (
    <>
      <div className="w-full flex flex-col">
        <img
          src={config.navbar?.icon[theme]}
          alt="oditi Company Icon"
          className={`!size-12 mx-auto mb-6 old-d-${collapse}-none`}
        />

        {user.doesSessionExist && (
          <>
            <User
              name={user.username}
              badges={user.badges}
              description="Your Profile"
              avatarProps={{
                src: user.icon,
              }}
              as={Link}
              to={generatePath(routes.userProfile, { username: user.username })}
              onClick={onClose}
              state={{
                referrer: "navbar",
              }}
              className="rounded-3xl p-4 border-2 border-primary-100 mb-6"
            />

            <CompleteOnboarding />

            {offcanvas.map(({ name, onClick, icon: Icon, selectedIcon: SelectedIcon }) => (
              <div
                key={name}
                onClick={onClick}
                className="flex gap-4 items-center p-4 -mx-4 rounded-3xl bg-content2 text-foreground hover:bg-content1"
                role="button"
                tabIndex={0}
                onMouseEnter={(e) =>
                  e.target.children[0]?.classList.add(css(animationStyles.pulse))
                }
                onMouseLeave={(e) =>
                  e.target.children[0]?.classList.remove(css(animationStyles.pulse))
                }
              >
                {location.split("/")[1] === name.toLowerCase() ? (
                  <SelectedIcon className="!size-8" />
                ) : (
                  <Icon className="!size-8" />
                )}
                <span className="capitalize text-xl">{name}</span>
              </div>
            ))}
          </>
        )}
      </div>

      {user.doesSessionExist && (
        <Button
          color="primary"
          size="lg"
          onClick={logout}
          trackingName="logout"
          trackingLocation="drawer"
          className="min-h-12"
        >
          Logout
        </Button>
      )}
    </>
  );
}
OffcanvasAccount.propTypes = {
  collapse: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  offcanvas: PropTypes.arrayOf(PropTypes.shape({})),
};
