import { useState, useContext, useEffect } from "react";
import { UserContext } from "features/user";
import { Button, ToggleButton, Row, Col, Spinner } from "ui";
import { useTags, CheckboxGroup } from "forms";
import api from "adapters/api";
import PropTypes from "prop-types";

export default function OnboardingTopics({ onComplete }) {
  const { user } = useContext(UserContext);
  const { tags: topics, loading: topicsLoading } = useTags({
    selectedUrl: `users/${user.id}/tags`,
  });
  const [loading, setLoading] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);

  useEffect(
    () => setSelectedTopics(Object.keys(topics).filter((topic) => topics[topic].checked)),
    [topics],
  );

  const handleCompleteUserOnboarding = async () => {
    setLoading(true);

    const payload = {
      metadata: JSON.stringify({
        completed_onboarding: true,
      }),
    };

    const tags = {
      values: selectedTopics,
      group: "design_interest",
    };

    const results = await Promise.all([
      api.patch({
        url: "users/",
        data: payload,
        headers: { "Content-Type": "multipart/form-data" },
      }),
      api.post({ url: `users/${user.id}/tags`, data: tags }),
    ]);

    if (results.filter((res) => res.success).length === 2) onComplete();

    setLoading(false);
  };

  return (
    <div data-testid="user-onboarding-topics">
      <h2 className="capitalize text-4xl">Personalize your feed</h2>
      <p className="mb-8">(Optionally) Pick a maximum of 3 topics</p>

      <CheckboxGroup orientation="horizontal" value={selectedTopics} onChange={setSelectedTopics}>
        <Row className="gap-y-3 mb-8">
          {Object.entries(topics).map(([value, { label }]) => (
            <Col xs={6}>
              <ToggleButton
                classNames={{
                  base: "min-w-full",
                  label: "uppercase py-3",
                }}
                value={value}
                isDisabled={selectedTopics.length >= 3 && !selectedTopics.includes(value)}
              >
                {label}
              </ToggleButton>
            </Col>
          ))}
        </Row>
      </CheckboxGroup>

      {topicsLoading && (
        <div className="w-full flex justify-center mb-12">
          <Spinner />
        </div>
      )}

      <Button
        onClick={handleCompleteUserOnboarding}
        color="primary"
        isLoading={loading}
        trackingName="continue onboarding"
        fullWidth
      >
        Continue
      </Button>
    </div>
  );
}

OnboardingTopics.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
