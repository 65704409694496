import { useState } from "react";
import { Link } from "react-router-dom";
import { SubmissionCard } from "features/submission";
import { BriefCard } from "features/brief";
import { css } from "aphrodite";
import { Row, Col, CardGrid, Button, Dropdown, User, Spinner } from "ui";
import { Input } from "forms";
import debounce from "lodash.debounce";
import animationStyles from "ui/animations";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import routes from "default/routes";
import api from "adapters/api";
import PropTypes from "prop-types";
import useKeyGen from "hooks/useKeyGen";

function ShowMoreButton({ show, to, onHide }) {
  if (!show) return null;

  return (
    <Button
      size="lg"
      color="primary"
      className="uppercase w-full"
      as={Link}
      to={to}
      onClick={onHide}
    >
      Show All
    </Button>
  );
}
ShowMoreButton.propTypes = {
  show: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
};

function SearchField({ option, handleOption, handleSearch }) {
  const debounceSearch = debounce((e) => handleSearch(e.target.value), 300);

  return (
    <div className="flex sticky top-0 right-0 w-full h-full z-10 px-8 sm:px-12 py-4 backdrop-blur-lg bg-content2/50 text-foreground">
      <Input
        label={`Search ${option?.currentKey || "Submissions"}...`}
        classNames={{
          inputWrapper:
            "bg-content1 rounded-e-none data-[hover=true]:bg-content1 group-data-[focus=true]:bg-content1 group-data-[focus-visible=true]:ring-transparent group-data-[focus-visible=true]:ring-0 group-data-[focus-visible=true]:ring-offset-0",
        }}
        onChange={debounceSearch}
        autoFocus
      />

      <Dropdown>
        <Dropdown.DropdownTrigger>
          <Button
            isIconOnly
            radius="none"
            className="h-full rounded-e-full px-8 bg-content1 shadow-2xl border-s-2"
          >
            <FilterListIcon />
          </Button>
        </Dropdown.DropdownTrigger>

        <Dropdown.DropdownMenu
          selectionMode="single"
          disallowEmptySelection
          selectedKeys={option}
          onSelectionChange={handleOption}
        >
          <Dropdown.DropdownItem key="submissions">Submissions</Dropdown.DropdownItem>
          <Dropdown.DropdownItem key="briefs">Briefs</Dropdown.DropdownItem>
          <Dropdown.DropdownItem key="profiles">Profiles</Dropdown.DropdownItem>
        </Dropdown.DropdownMenu>
      </Dropdown>
    </div>
  );
}
SearchField.propTypes = {
  option: PropTypes.string.isRequired,
  handleOption: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default function OffcanvasSearch({ onClose }) {
  const [option, setOption] = useState(new Set(["submissions"]));
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState("");
  const [showMore, setShowMore] = useState({
    briefs: false,
    submissions: false,
  });
  const keyGen = useKeyGen();
  const currentOption = option?.currentKey || "submissions";

  const handleSearch = async (searchTerm, newOption) => {
    if (!searchTerm) return;

    setTerm(searchTerm);
    setLoading(true);

    newOption = newOption?.currentKey ?? currentOption;

    const apiCalls = [
      ...(newOption === "profiles"
        ? [api.get({ url: "users/", params: { search: searchTerm } })]
        : []),
      ...(newOption === "profiles"
        ? [api.get({ url: "organisations/", params: { search: searchTerm } })]
        : []),
      ...(newOption === "briefs"
        ? [api.get({ url: "briefs/", params: { search: searchTerm } })]
        : []),
      ...(newOption === "submissions"
        ? [api.get({ url: "submissions/", params: { search: searchTerm } })]
        : []),
    ];

    const allResults = await Promise.all(apiCalls);

    // Only if they all succeed do we want to continue
    if (allResults.filter((res) => res.success).length !== apiCalls.length) {
      setLoading(false);
      return;
    }

    let allData;
    let totalPages = 0;
    if (allResults.length > 1) {
      allData = allResults[0].data.concat(allResults[1].data);
      totalPages =
        JSON.parse(allResults[0].headers?.get("X-pagination") || "{}").total_pages +
        JSON.parse(allResults[1].headers?.get("X-pagination") || "{}").total_pages;
    } else {
      allData = allResults[0].data;
      totalPages = JSON.parse(allResults[0].headers?.get("X-pagination") || "{}").total_pages;
    }

    setResults({ [newOption]: allData });
    setShowMore({ [newOption]: totalPages > 1 });
    setLoading(false);
  };

  return (
    <div className="w-full flex flex-col">
      <div className="px-8 sm:px-16 pt-8 sm:pt-16 flex items-center z-10">
        <span
          className="flex items-center gap-1 rounded-3xl px-4 py-2 -mt-2 -ml-4 text-xl bg-content2 text-foreground hover:bg-content1"
          role="button"
          tabIndex={0}
          onClick={onClose}
          onMouseEnter={(e) => e.target.children[0]?.classList.add(css(animationStyles.pulse))}
          onMouseLeave={(e) => e.target.children[0]?.classList.remove(css(animationStyles.pulse))}
        >
          <ArrowBackIcon />
          Search
        </span>
      </div>

      <SearchField
        option={option}
        handleOption={(newOption) => {
          handleSearch(term, newOption);
          setOption(newOption);
        }}
        handleSearch={handleSearch}
      />

      <div className="px-8 sm:px-12">
        {currentOption === "profiles" && results.profiles?.length > 0 && !loading && (
          <Row className="mb-6">
            {results.profiles.map((profile) => {
              let profileData;
              if ("username" in profile) {
                profileData = {
                  icon: profile.icon,
                  type: "People",
                  name: profile.username,
                  link: `/user/${profile.username}`,
                };
              } else {
                profileData = {
                  icon: profile.icon,
                  type: "Company",
                  name: profile.name,
                  link: `/organisation/${profile.id}`,
                };
              }

              return (
                <Col
                  key={keyGen.getKey(profileData)}
                  as={Link}
                  to={profileData.link}
                  className="my-4"
                  sm={12}
                >
                  <User
                    className="rounded-full border-2 border-primary-100"
                    name={profileData.name}
                    avatarProps={{
                      src: profileData.icon,
                    }}
                    description={profileData.type}
                    badges={profileData.badges}
                  />
                </Col>
              );
            })}
          </Row>
        )}

        {currentOption === "briefs" && results.briefs?.length > 0 && !loading && (
          <>
            <CardGrid
              entries={results.briefs}
              card={BriefCard}
              sizing={{
                xs: 12,
                sm: 6,
                md: 12,
              }}
            />

            <ShowMoreButton
              show={showMore.briefs}
              to={`${routes.briefs}?search=${term}`}
              onHide={onClose}
            />
          </>
        )}

        {currentOption === "submissions" && results.submissions?.length > 0 && !loading && (
          <>
            <CardGrid
              entries={results.submissions}
              card={SubmissionCard}
              sizing={{
                xs: 12,
                sm: 6,
                md: 12,
              }}
            />

            <ShowMoreButton
              show={showMore.submissions}
              to={`${routes.voting}?search=${term}`}
              onHide={onClose}
            />
          </>
        )}

        {(results.profiles?.length <= 0 ||
          results.briefs?.length <= 0 ||
          results.submissions?.length <= 0) &&
          !loading && (
            <span className="text-xl absolute top-0 left-0 h-full w-full flex flex-col items-center justify-center gap-2">
              <SentimentVeryDissatisfiedIcon className="!size-10" />
              No {currentOption} could be found
            </span>
          )}

        {Object.keys(results).length <= 0 && !loading && (
          <span className="text-xl absolute top-0 left-0 h-full w-full flex flex-col items-center justify-center gap-2">
            <SearchIcon className="!size-10" />
            Search Oditi for {currentOption}!
          </span>
        )}

        {loading && (
          <div className="w-full flex justify-center mt-6">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}
OffcanvasSearch.propTypes = {
  onClose: PropTypes.func.isRequired,
};
