import { forwardRef, useContext, useState } from "react";
import {
  MDXEditor,
  UndoRedo,
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ListsToggle,
  CreateLink,
  linkDialogPlugin,
  linkPlugin,
  toolbarPlugin,
  listsPlugin,
  headingsPlugin,
} from "@mdxeditor/editor";
import { ThemeContext } from "ui";
import { Textarea } from "forms";
import { tv } from "tailwind-variants";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

function ToolbarComponents() {
  return (
    <>
      <UndoRedo />
      <BoldItalicUnderlineToggles />
      <CreateLink />
      <ListsToggle />
      <BlockTypeSelect />
    </>
  );
}

const richMedia = tv({
  slots: {
    base: "bg-default-100 rounded-3xl overflow-hidden",
    content: "",
    label: "text-small mb-3 block",
  },
  variants: {
    color: {
      danger: {
        base: "bg-danger-50 text-danger",
      },
    },
  },
});

const MarkdownInput = forwardRef(
  (
    {
      name,
      color,
      label,
      isInvalid,
      maxWords,
      placeholder,
      defaultValue,
      className,
      classNames,
      markdownProps,
      inputClassNames,
      ...inputProps
    },
    ref,
  ) => {
    const {
      base,
      content,
      label: labelClassNames,
    } = richMedia({ color: isInvalid ? "danger" : color });
    const { theme } = useContext(ThemeContext);
    const [value, setValue] = useState(defaultValue);

    return (
      <div>
        {label && (
          <label htmlFor={name} className={labelClassNames()}>
            {label}
          </label>
        )}

        <div
          className={twMerge(base(), className, classNames.base)}
          onSubmit={(e) => e.stopPropagation()}
        >
          <MDXEditor
            markdown={defaultValue}
            plugins={[
              toolbarPlugin({
                toolbarContents: ToolbarComponents,
              }),
              linkPlugin(),
              linkDialogPlugin(),
              listsPlugin(),
              headingsPlugin(),
            ]}
            className={twMerge(
              content(),
              theme === "dark" && "dark-theme dark-editor",
              classNames.content,
            )}
            onChange={setValue}
            {...{ ref, placeholder }}
            {...markdownProps}
          />
        </div>

        {/* TODO: with hook form this input isnt needed and we can grab the value straight from the mdx */}
        <Textarea
          isReadOnly
          classNames={{
            inputWrapper: twMerge("hidden", inputClassNames?.inputWrapper),
            description: twMerge("text-right font-local", inputClassNames?.description),
            ...inputClassNames,
          }}
          id={name}
          description={maxWords && `${value.trim().split(/\s+/).length}/${maxWords} words`}
          {...{ name, value, isInvalid }}
          {...inputProps}
        />
      </div>
    );
  },
);
MarkdownInput.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  maxWords: PropTypes.number,
  isInvalid: PropTypes.bool,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    content: PropTypes.string,
    label: PropTypes.string,
  }),
  markdownProps: PropTypes.shape({}),
  inputClassNames: PropTypes.shape({
    inputWrapper: PropTypes.string,
    description: PropTypes.string,
  }),
};
MarkdownInput.defaultProps = {
  color: undefined,
  label: undefined,
  isInvalid: false,
  placeholder: "",
  maxWords: 100,
  defaultValue: "",
  className: "",
  classNames: {},
  markdownProps: {},
  inputClassNames: {},
};

export default MarkdownInput;
