import { useMemo, useContext } from "react";
import { useDisclosure } from "@nextui-org/react";
import PropTypes from "prop-types";

import { UserContext, AddUserInfoModal } from "features/user";
import { FeedbackPromptModal, useFeedback } from "features/feedback";
import { useProtectedAction } from "features/authentication";
import { abbreviateNumber } from "utils/helpers";
import { Button } from "ui";
import useAnimateVoteButton from "hooks/useAnimateVoteButton";
import VoteIcon from "icons/VoteIcon";
import Mixpanel from "adapters/mixpanel";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";

import useSubmissionWithActions from "../../hooks/useSubmissionWithActions";

export default function SubmissionVoteButton({ trackingLocation, submissionId }) {
  const { user } = useContext(UserContext);
  const { data: feedback } = useFeedback(submissionId);
  const { submission, useSubmissionVote } = useSubmissionWithActions(submissionId);

  const showVotes = submission?.voted && !useSubmissionVote.isPending;
  const hasGivenFeedback = useMemo(
    () => feedback?.comments?.some((f) => f.user.id === user.id),
    [feedback],
  );
  const { animateVerified, animateVotes } = useAnimateVoteButton(showVotes);

  const {
    isOpen: isUserInfoModalOpen,
    onOpen: openUserInfoModal,
    onClose: closeUserInfoModal,
  } = useDisclosure();
  const {
    isOpen: isFeedbackModalOpen,
    onOpen: openFeedbackModal,
    onClose: closeFeedbackModal,
  } = useDisclosure();
  const FEEDBACK_PROMPT_PROBABILITY = 0.4;

  const [handleOnClick] = useProtectedAction(async () => {
    if (showVotes) return;
    Mixpanel.trackButton("Action Button", "vote", trackingLocation);

    if (!(user.dob && user.country_code && user.gender)) {
      openUserInfoModal();
      return;
    }

    await useSubmissionVote.mutateAsync();
    if (!hasGivenFeedback && Math.random() < FEEDBACK_PROMPT_PROBABILITY) openFeedbackModal();
  });

  return (
    <>
      <AddUserInfoModal
        show={isUserInfoModalOpen}
        onHide={closeUserInfoModal}
        onSuccess={async () => {
          await useSubmissionVote.mutateAsync();
          closeUserInfoModal();
        }}
        title="Let's verify your vote!"
        footerProps={{
          confirmText: "Confirm Vote",
        }}
      />

      <FeedbackPromptModal
        show={isFeedbackModalOpen}
        onHide={closeFeedbackModal}
        submissionId={submissionId}
      />

      <Button
        color="success"
        size="lg"
        startContent={showVotes ? <VerifiedOutlinedIcon ref={animateVerified} /> : <VoteIcon />}
        className="sm:px-8 mr-2 gap-2 uppercase"
        onClick={handleOnClick}
        isLoading={useSubmissionVote.isPending || isUserInfoModalOpen}
        data-testid="vote-button"
        spinnerPlacement="end"
      >
        {showVotes ? (
          <span ref={animateVotes} data-testid="vote-count">
            {abbreviateNumber(submission?.votes, 1)}
          </span>
        ) : (
          "Vote"
        )}
      </Button>
    </>
  );
}

SubmissionVoteButton.propTypes = {
  trackingLocation: PropTypes.string.isRequired,
  submissionId: PropTypes.number.isRequired,
};
