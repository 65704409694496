import { useState } from "react";
import { Collapse, Modal } from "ui";
import {
  Input,
  ControlledTextarea,
  useForm,
  yupResolver,
  RadioGroup,
  Radio,
  Controller,
  handleFormError,
  Form,
} from "forms";
import { object, string } from "yup";
import { useProtectedAction } from "features/authentication";
import PropTypes from "prop-types";
import SendIcon from "@mui/icons-material/Send";

const defaultReasons = [
  "Spam",
  "Plagiarism",
  "Harmful or illegal",
  "Use of generative AI",
  "Other",
];

const schema = object().shape({
  reason: string().required("You must select a reason"),
  details: string().when("reason", {
    is: "Other",
    then: string().required("Details are required when reason is other"),
  }),
  url: string().when("reason", {
    is: "Plagiarism",
    then: string().required(),
  }),
});

export default function ReportModal({
  onHide,
  title,
  mutateFn,
  show = false,
  reasons = defaultReasons,
}) {
  const [reportSent, setReportSent] = useState(false);
  const {
    register,
    control,
    watch,
    setError,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { mutate, isPending } = mutateFn();
  const reason = watch("reason");

  const handleClose = () => {
    reset();
    setReportSent(false);
    onHide();
  };

  const [handleSendReport] = useProtectedAction((formData) =>
    mutate(formData, {
      onSuccess: () => setReportSent(true),
      onError: (error, data) => handleFormError({ error, data, setError, errorPage: "other" }),
    }),
  );

  return (
    <Modal isOpen={show} onOpenChange={handleClose}>
      <Modal.ModalHeader>{title}</Modal.ModalHeader>

      <Form onSubmit={handleSubmit(handleSendReport)}>
        <Modal.ModalBody className="font-roman gap-5">
          {!reportSent ? (
            <>
              <small>
                Thank you for helping to keep our platform safe. Please state the reason for your
                report:
              </small>

              <Controller
                control={control}
                name="reason"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    isInvalid={!!errors.reason}
                    errorMessage={errors.reason?.message}
                  >
                    {reasons.map((r) => (
                      <Radio key={r} value={r}>
                        {r}
                      </Radio>
                    ))}
                  </RadioGroup>
                )}
              />

              <Collapse isOpen={reason?.toLowerCase() === "plagiarism"}>
                <Input
                  {...register("url")}
                  label="Link to the plagiarised work"
                  isInvalid={!!errors.url}
                  errorMessage={errors.url?.message}
                />
              </Collapse>

              <ControlledTextarea
                control={control}
                name="details"
                label="Additional Details (optional)"
                maxLength={200}
                minRows={4}
                maxRows={10}
                isInvalid={!!errors.details}
                errorMessage={errors.details?.message}
              />
            </>
          ) : (
            <div className="text-center">
              <small>
                Thank you for your report.
                <br />
                We may be in touch to ask for further information.
              </small>
            </div>
          )}
        </Modal.ModalBody>

        <Modal.ModalActionsFooter
          onCancel={handleClose}
          cancelText={reportSent ? "Close" : "Cancel"}
          confirmText="Send"
          confirmProps={{
            isDisabled: reason === "" || isPending || reportSent,
            isLoading: isPending,
            endContent: <SendIcon className="!size-4" />,
          }}
        />
      </Form>
    </Modal>
  );
}

ReportModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  mutateFn: PropTypes.func.isRequired,
  show: PropTypes.bool,
  reasons: PropTypes.objectOf(PropTypes.string),
};
