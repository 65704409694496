import { useState } from "react";
import { Avatar, CropModal } from "ui";
import { objectUrlToFile } from "utils/helpers";
import { useDisclosure } from "@nextui-org/react";
import { useController } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import FileInput from "forms/file/FileInput";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PropTypes from "prop-types";

function PreviewAvatar({ value, isInvalid = false, name = "", changeText = "Change Avatar" }) {
  return (
    <div className="text-center">
      <div className="old-hover-overlay relative inline-flex mb-5">
        <Avatar
          src={value instanceof File ? URL.createObjectURL(value) : value}
          className="!size-52"
          name={name}
        />

        <div className="old-overlay-fade-top absolute top-0 left-0 size-52 rounded-full text-primary-foreground flex items-center justify-center flex-col">
          <FileUploadOutlinedIcon className="!size-10" />
          <span>Upload</span>
        </div>
      </div>

      <div
        className={twMerge(
          "flex items-center justify-center text-primary-600 gap-1",
          isInvalid && "text-danger",
        )}
      >
        <FileUploadOutlinedIcon />
        <span>{changeText}</span>
      </div>
    </div>
  );
}
PreviewAvatar.propTypes = {
  value: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  name: PropTypes.string,
  changeText: PropTypes.string,
};

export default function ControlledAvatarInput({
  name,
  control,
  defaultValue = "",
  avatarProps = {},
  cropProps = {},
  ...fileProps
}) {
  const [file, setFile] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    field: { onChange, ...field },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <>
      <FileInput
        render={PreviewAvatar}
        onChange={(newFile) => {
          setFile(newFile);
          onOpen();
        }}
        renderProps={avatarProps}
        {...field}
        {...fileProps}
      />

      <CropModal
        onSuccess={async (croppedSrc) => onChange(await objectUrlToFile(croppedSrc, file.name))}
        show={isOpen}
        onHide={onClose}
        src={isOpen ? URL.createObjectURL(file) : ""}
        maxWidth={512}
        maxHeight={512}
        aspect={1 / 1}
        cropShape="round"
        {...cropProps}
      />
    </>
  );
}
ControlledAvatarInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.string,
  avatarProps: PropTypes.shape({}),
  cropProps: PropTypes.shape({}),
};
