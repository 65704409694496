import { forwardRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Button } from "ui";
import { formatBytes } from "utils/helpers";
import PropTypes from "prop-types";
import Input from "forms/Input";

const acceptedFileTypes = ({ accept, maxSize } = {}) => {
  let acceptMsg = "We can accept any files";

  if (accept && accept.split(",").length > 0)
    acceptMsg = `We can accept only ${accept.split(",").join(", ")} files`;

  if (maxSize) acceptMsg += ` That are less than ${formatBytes(maxSize)} in size.`;
  return acceptMsg;
};

function DefaultRender({ value, handleOpenFileDialog }) {
  return (
    <div className="flex justify-between items-center">
      {value ? value.map((f) => f.name).join(", ") : "Choose File"}

      <Button color="primary" onClick={handleOpenFileDialog} trackingName="browse file upload">
        Select Files
      </Button>
    </div>
  );
}
DefaultRender.propTypes = {
  value: PropTypes.string.isRequired,
  handleOpenFileDialog: PropTypes.func.isRequired,
};

const FileInput = forwardRef(
  (
    {
      name,
      onChange,
      value,
      maxSize,
      maxFiles,
      accept,
      isInvalid,
      errorMessage,
      render,
      renderProps,
      ...inputProps
    },
    ref,
  ) => {
    const handleFileUpload = useCallback(
      (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
          toast.error(<div data-testid="error-toast">{rejectedFiles[0].errors[0].message}</div>, {
            autoClose: 3000,
          });
          return;
        }

        onChange(maxFiles > 1 ? acceptedFiles : acceptedFiles[0]);
      },
      [maxFiles, onChange],
    );

    const { getRootProps, getInputProps, isDragReject, isDragAccept, open } = useDropzone({
      multiple: maxFiles > 1,
      onDrop: handleFileUpload,
      maxFiles,
      maxSize,
      accept,
      ...inputProps,
    });

    const Render = render;

    return (
      <div {...getRootProps()}>
        <Render
          handleOpenFileDialog={open}
          isDragAccept={isDragAccept}
          isDragReject={isDragReject}
          acceptedFileTypes={acceptedFileTypes({
            accept: getInputProps()?.accept,
            maxSize,
          })}
          {...{ value, isInvalid, errorMessage }}
          {...renderProps}
        />

        <Input
          {...getInputProps({
            name,
            isInvalid,
            errorMessage,
            ref,
            classNames: {
              inputWrapper: "hidden",
            },
          })}
        />
      </div>
    );
  },
);
FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  accept: PropTypes.shape({}),
  isInvalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  render: PropTypes.elementType,
  renderProps: PropTypes.shape({}),
};
FileInput.defaultProps = {
  value: "",
  maxSize: 26214400,
  maxFiles: 1,
  accept: {
    "image/png": [],
    "image/jpg": [],
    "image/jpeg": [],
    "image/webp": [],
  },
  isInvalid: false,
  errorMessage: null,
  render: DefaultRender,
  renderProps: {},
};

export default FileInput;
