import { useContext } from "react";
import { Link } from "react-router-dom";
import { NavigationConfigContext } from "features/navigation";
import { Section, Button } from "ui";
import ErrorContext from "features/report/context/ErrorContext";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PropTypes from "prop-types";

const defaultText = "Bummer. \n The requested page could not be found.";

export default function NotFound({ text = defaultText, issuePage = "" }) {
  const { config } = useContext(NavigationConfigContext);
  const { showError } = useContext(ErrorContext);

  return (
    <Section className="w-screen h-screen w-full flex items-center justify-center flex-col p-1 text-center">
      <h3 className="whitespace-pre-line">{text}</h3>

      <div className="flex w-full justify-center gap-4">
        <Button
          startContent={<ErrorOutlineIcon />}
          onClick={() =>
            showError({
              defaultReason: "issue",
              defaultPage: issuePage,
            })
          }
          size="lg"
          variant="faded"
        >
          Report An Issue
        </Button>

        <Button
          as={Link}
          to={config.redirectHome}
          trackingName="view home"
          color="success"
          startContent={<HomeOutlinedIcon />}
          size="lg"
        >
          Go Home
        </Button>
      </div>
    </Section>
  );
}

NotFound.propTypes = {
  text: PropTypes.string,
  issuePage: PropTypes.string,
};
