import { socialPrefixes } from "features/profile/data/socialData";
import { Modal } from "ui";
import { object, string } from "yup";
import { Input, Form, useForm, Controller, yupResolver } from "forms";
import PropTypes from "prop-types";

const schema = object().shape({
  handle: string()
    .min(1)
    .matches(/^[^@]/, "Handle first letter cannot be @")
    .matches(/^\S*$/, "Handle cannot contain spaces")
    .required(),
});

export default function AddSocialModal({ onHide, onComplete, social, show = false }) {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    reset();
    onHide();
  };

  return (
    <Modal isOpen={show} onOpenChange={handleClose} data-testid="add-social-modal">
      <Modal.ModalHeader className="capitalize">Link {social} Account</Modal.ModalHeader>

      <Modal.ModalBody>
        <Form
          onSubmit={(e) => {
            e.stopPropagation(); // prevent submit of parent form
            handleSubmit((data) => {
              onComplete(data.handle);
              handleClose();
            })(e);
          }}
        >
          <Controller
            control={control}
            name="handle"
            render={({ field: { value, ...rest } }) => (
              <Input
                {...rest}
                value={value}
                label={`Add ${social} handle`}
                errorMessage={errors.handle?.message}
                isInvalid={!!errors.handle}
                startContent={
                  <div className="pointer-events-none flex items-center">
                    <span className="text-default-400 text-small">@</span>
                  </div>
                }
                description={`${socialPrefixes[social]}${value ?? ""}`}
              />
            )}
          />

          <small className="font-roman">
            Your {social} handle uniquely identifies your {social} account and is typically the
            final part of your {social} profile url, or can be found on your {social} page.
          </small>

          <Modal.ModalActionsFooter confirmText="Add Social" onCancel={handleClose} />
        </Form>
      </Modal.ModalBody>
    </Modal>
  );
}

AddSocialModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  social: PropTypes.oneOf(["instagram", "tiktok", "facebook", "twitter", "youtube"]).isRequired,
  show: PropTypes.bool,
};
