import { forwardRef } from "react";
import { Avatar } from "ui";
import Autocomplete from "forms/Autocomplete";
import iso3311a2 from "iso-3166-1-alpha-2";
import PropTypes from "prop-types";

const CountryPicker = forwardRef(({ defaultSelectedKey, ...props }, ref) => (
  <Autocomplete
    label="Country"
    ref={ref}
    defaultSelectedKey={iso3311a2.getCountry(defaultSelectedKey)}
    {...props}
  >
    {iso3311a2.getCountries().map((country) => (
      <Autocomplete.AutocompleteItem
        key={country}
        startContent={
          <Avatar
            alt={country}
            className="w-6 h-6"
            imgProps={{ loading: "lazy" }}
            src={`https://flagcdn.com/${iso3311a2.getCode(country).toLowerCase()}.svg`}
          />
        }
      >
        {country}
      </Autocomplete.AutocompleteItem>
    ))}
  </Autocomplete>
));
CountryPicker.propTypes = {
  defaultSelectedKey: PropTypes.string,
};
CountryPicker.defaultProps = {
  defaultSelectedKey: "",
};

export default CountryPicker;
