import { useContext } from "react";
import { parseDate } from "@internationalized/date";
import { object, string, date } from "yup";
import { UserContext, useUpdateUser } from "features/user";
import { Modal } from "ui";
import {
  useForm,
  Form,
  Controller,
  Select,
  yupResolver,
  handleFormError,
  CountryPicker,
  DatePicker,
} from "forms";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import iso3311a2 from "iso-3166-1-alpha-2";

const schema = object().shape({
  dob: date()
    .required()
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 13)),
      "You must be a minimum of 13 years old",
    ),
  gender: string().required("Gender is required"),
  country_code: string()
    .required("Country is required")
    .oneOf(iso3311a2.getCountries(), "Not a valid country"),
});

export default function AddUserInfoModal({ show, onHide, onSuccess, title, footerProps = {} }) {
  const { user } = useContext(UserContext);
  const { mutate, isPending } = useUpdateUser();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    reset();
    onHide();
  };

  return (
    <Modal isOpen={show} onClose={handleClose} data-testid="add-user-info-modal">
      <Modal.ModalBody className="p-6">
        <section className="flex flex-col my-4 text-center justify-center">
          <span className="text-3xl">{title}</span>
          <span className="uppercase text-primary-600">Help us keep competitions fair</span>
        </section>

        <Form
          onSubmit={handleSubmit((formData) =>
            mutate(
              {
                ...formData,
                dob: dayjs(formData.dob).format("YYYY-MM-DD"),
                country_code: iso3311a2.getCode(formData.country_code),
              },
              {
                onSuccess,
                onError: (error, data) =>
                  handleFormError({ error, data, setError, errorPage: "profile" }),
              },
            ),
          )}
        >
          <Controller
            control={control}
            name="dob"
            defaultValue={user.dob ? parseDate(user.dob) : null}
            render={({ field: { value, ...field } }) => (
              <DatePicker
                {...field}
                defaultValue={value}
                label="Date of Birth"
                errorMessage={errors.dob?.message}
                isInvalid={!!errors.dob}
              />
            )}
          />

          <Select
            label="Gender"
            {...register("gender")}
            defaultSelectedKeys={[user.gender || ""]}
            errorMessage={errors.gender?.message}
            isInvalid={!!errors.gender}
          >
            <Select.SelectItem key="male">Male</Select.SelectItem>
            <Select.SelectItem key="female">Female</Select.SelectItem>
            <Select.SelectItem key="non_binary">Non Binary</Select.SelectItem>
            <Select.SelectItem key="not_to_say">Prefer not to say</Select.SelectItem>
          </Select>

          <CountryPicker
            {...register("country_code")}
            defaultSelectedKey={user.country_code}
            errorMessage={errors.country_code?.message}
            isInvalid={!!errors.country_code}
          />

          <Modal.ModalActionsFooter
            onCancel={handleClose}
            cancelProps={{
              variant: "light",
            }}
            confirmProps={{
              type: "submit",
              isLoading: isPending,
            }}
            {...footerProps}
          />
        </Form>
      </Modal.ModalBody>
    </Modal>
  );
}

AddUserInfoModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  footerProps: PropTypes.shape({}),
};
