import { LeaderboardList, leaderboardEntryPropTypes } from "features/leaderboard";
import PropTypes from "prop-types";

export default function LandingIncentives({ users }) {
  return (
    <div className="absolute top-[20%] text-center flex flex-col gap-4 items-center w-full px-4">
      <h2 className="max-w-[900px]">Win prizes and climb the ranks</h2>
      <p className="mb-0">
        Earn rewards and grow your reputation within the design community and beyond.
      </p>

      <div className="max-w-[556px] w-full">
        <LeaderboardList entries={users} scoreName="points" />
      </div>
    </div>
  );
}
LandingIncentives.propTypes = {
  users: PropTypes.arrayOf(leaderboardEntryPropTypes).isRequired,
};
