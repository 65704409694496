import { Spinner } from "@nextui-org/react";
import { twMerge } from "tailwind-merge";
import styled from "styled-components";

const StyledSpinner = styled(Spinner).attrs((props) => ({
  classNames: {
    circle1: twMerge("border-b-foreground", props.classNames?.circle1),
    circle2: twMerge("border-b-foreground", props.classNames?.circle2),
  },
}))``;

export default StyledSpinner;
