import { Modal } from "ui";
import { briefPropTypes, useEnterBriefMutations } from "features/brief";
import { RadioGroup, Radio, useForm, Controller, handleFormError, Form } from "forms";
import { useProtectedAction } from "features/authentication";
import PropTypes from "prop-types";

const reasons = {
  length: "I didn't have enough time to complete the brief",
  accidental: "I registered by accident",
  description: "The brief instructions weren't clear enough",
  incentives: "I lost interest and motivation",
};

export default function BriefNotInterestedModal({ onHide, brief, show = false }) {
  const { control, reset, watch, setError, handleSubmit } = useForm();
  const {
    leaveBrief: { mutate, isPending },
  } = useEnterBriefMutations();
  const reason = watch("reason");

  const handleClose = () => {
    reset();
    onHide();
  };

  const [leaveBrief] = useProtectedAction((formData) =>
    mutate(
      { id: brief.id, reason: formData.reason },
      {
        onSuccess: handleClose,
        onError: (error, data) =>
          handleFormError({ error, data, setError, errorPage: "enter_brief" }),
      },
    ),
  );

  return (
    <Modal isOpen={show} onOpenChange={handleClose} data-testid="brief-not-interested-modal">
      <Modal.ModalHeader>No Longer Interested In This Brief?</Modal.ModalHeader>

      <Form onSubmit={handleSubmit(leaveBrief)} className="gap-0">
        <Modal.ModalBody className="gap-0 font-roman">
          <small className="mb-3">
            Let us know why to help us improve our briefs in the future!
          </small>

          <Controller
            name="reason"
            control={control}
            rules={{
              required: "You must provide a reason",
            }}
            render={({ field, formState: { errors } }) => (
              <RadioGroup
                {...field}
                isInvalid={!!errors.reason}
                errorMessage={errors.reason?.message}
              >
                {Object.entries(reasons).map(([key, label]) => (
                  <Radio key={key} value={key}>
                    {label}
                  </Radio>
                ))}
              </RadioGroup>
            )}
          />
        </Modal.ModalBody>

        <Modal.ModalActionsFooter
          onCancel={handleClose}
          confirmProps={{
            isDisabled: !reason || isPending,
            isLoading: isPending,
          }}
        />
      </Form>
    </Modal>
  );
}

BriefNotInterestedModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  brief: briefPropTypes.isRequired,
  show: PropTypes.bool,
};
