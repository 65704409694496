import { useContext } from "react";
import { useMutation, client } from "api";
import { stringifyFormData } from "utils/helpers";
import UserContext from "features/user/context/UserContext";

const baseURL = "organisations/";

const useUpdateOrganisation = () => {
  const { user, setUser } = useContext(UserContext);
  return useMutation({
    mutationFn: (data) =>
      client.patch(baseURL + user.organisation.id, stringifyFormData(data, ["icon"]), {
        headers: { "Content-Type": "multipart/form-data" },
      }),
    onSuccess: ({ data }) => {
      setUser({ ...user, organisation: data });
    },
  });
};

export { useUpdateOrganisation };
