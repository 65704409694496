import { useMemo, useContext } from "react";
import { generatePath } from "utils/helpers";
import { useNavigate, Link } from "react-router-dom";
import { briefListPropTypes } from "features/brief/schemas/briefSchema";
import { NavigationConfigContext } from "features/navigation";
import { briefStatusContent } from "features/brief/data/briefData";
import { Chip, Card, Image, User } from "ui";
import routes from "default/routes";
import Mixpanel from "adapters/mixpanel";
import PropTypes from "prop-types";

export default function BriefCard({ entry: brief = {}, trackingLocation = "" }) {
  const { config } = useContext(NavigationConfigContext);
  const navigate = useNavigate();

  const handleTrackOnClick = () => {
    Mixpanel.trackButton("Card", "view brief", trackingLocation, {
      "Card Type": brief.type,
    });
    navigate(generatePath(routes.brief, { id: brief.id }));
  };

  const countdown = useMemo(() => {
    const { reminder } = briefStatusContent(brief)[brief.status];
    return reminder;
  }, [brief]);

  return (
    <Card
      isPressable
      allowTextSelectionOnPress
      isFooterBlurred
      shadow="none"
      className="h-full w-full bg-content4 text-primary-foreground"
    >
      <Card.CardHeader className="flex justify-between px-4 py-2 z-0" onClick={handleTrackOnClick}>
        <User
          name={brief.organisation.name}
          avatarProps={{
            src: brief.organisation.icon,
            size: "sm",
            isBordered: true,
          }}
          classNames={{
            base: "bg-transparent gap-3 px-0 z-20",
            name: "normal-case line-clamp-1 text-start hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-left before:transition-transform before:duration-500 before:scale-x-0 before:bg-current before:absolute before:left-0 before:bottom-0",
          }}
          as={Link}
          to={generatePath(config.redirectDefault, {
            wildcard: generatePath(routes.organisation, {
              id: brief.organisation.id,
            }),
          })}
          data-testid="image-card-profile"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />

        {countdown?.length > 0 && (
          <Chip color="danger" radius="sm" className="uppercase old-shake" size="lg">
            {countdown}
          </Chip>
        )}

        {(countdown?.length || 0) <= 0 && ["submitting", "voting"].includes(brief.status) && (
          <Chip radius="sm" size="lg" color="danger" className="uppercase old-pulse-shadow">
            Live
          </Chip>
        )}
      </Card.CardHeader>

      <Card.CardBody
        className="bg-background relative p-0 rounded-3xl after:bg-gradient-to-t after:to-50% overflow-hidden after:from-black/50 after:size-full after:absolute aspect-4/3"
        onClick={handleTrackOnClick}
      >
        <Image
          src={brief.thumbnail}
          alt="Brief card thumbnail"
          classNames={{
            img: "object-cover w-full h-full aspect-4/3 z-0",
            wrapper: "w-full h-full bg-cover bg-no-repeat bg-center",
          }}
          loading="lazy"
        />
      </Card.CardBody>

      <Card.CardFooter
        className="absolute bottom-2 bg-content2/40 w-[calc(100%_-_16px)] ms-2 rounded-3xl text-start"
        onClick={handleTrackOnClick}
      >
        <p className="font-bold text-xl line-clamp-2 mb-0">{brief.title}</p>
      </Card.CardFooter>
    </Card>
  );
}
BriefCard.propTypes = {
  entry: briefListPropTypes,
  trackingLocation: PropTypes.string,
};
