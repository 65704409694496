import { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Skeleton } from "ui";
import PropTypes from "prop-types";
import StarIcon from "@mui/icons-material/Star";

function TypewriterText({ text }) {
  const [displayText, setDisplayText] = useState("");
  const index = useRef(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDisplayText((prev) => {
        if (index.current < text.length) {
          index.current += 1;
          return text.slice(0, index.current);
        }
        clearInterval(intervalId);
        return prev;
      });
    }, 50);

    return () => clearInterval(intervalId);
  }, [text]);

  return <span className="font-bold grow">{displayText}</span>;
}
TypewriterText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default function LandingFeedback() {
  return (
    <>
      <div className="z-20 text-center mb-4 flex flex-col gap-4 px-4">
        <h2 className="max-w-[900px]">Improve with insights and feedback</h2>
        <p>
          Gain valuable insights and analytics to sharpen your skills and push your creative
          boundaries.
        </p>
      </div>

      <Row className="w-full sm:w-2/3 gap-y-6 px-3">
        <Col xs={12} lg={6}>
          <Card className="h-full">
            <Card.CardBody className="p-6 flex gap-4">
              <h3 className="flex items-center gap-1">
                <StarIcon /> Ai Feedback
              </h3>

              <Skeleton className="w-3/5 rounded-lg hidden sm:block">
                <div className="h-5 w-3/5 rounded-lg bg-default-200" />
              </Skeleton>

              <Skeleton className="w-4/5 rounded-lg hidden md:block">
                <div className="h-5 w-4/5 rounded-lg bg-default-200" />
              </Skeleton>

              <TypewriterText text="Your design shows great potential. Consider using more contrast in your color scheme to improve readability." />

              <Skeleton className="w-full rounded-lg hidden sm:block flex-end">
                <div className="h-5 w-full rounded-lg bg-default-200" />
              </Skeleton>
            </Card.CardBody>
          </Card>
        </Col>

        <Col className="flex flex-col gap-y-6">
          <Row className="gap-y-6">
            <Col xs={6}>
              <Card>
                <Card.CardBody className="p-6">
                  <h3>Engagement</h3>
                  <TypewriterText text="85%" />
                </Card.CardBody>
              </Card>
            </Col>

            <Col>
              <Card className="h-full">
                <Card.CardBody className="p-6">
                  <h3>Votes</h3>
                  <TypewriterText text="125" />
                </Card.CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="h-full hidden lg:flex">
            <Col xs={12}>
              <Card className="h-full">
                <Card.CardBody className="p-6 flex gap-4">
                  <h3>Other Metics</h3>

                  <Skeleton className="w-3/5 rounded-lg">
                    <div className="h-5 w-3/5 rounded-lg bg-default-200" />
                  </Skeleton>

                  <Skeleton className="w-full rounded-lg">
                    <div className="h-5 w-full rounded-lg bg-default-200" />
                  </Skeleton>
                </Card.CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
