import { Link, useNavigate, generatePath } from "react-router-dom";
import { SubmissionCard, submissionListPropTypes } from "features/submission";
import { AutoScrollLoader, CardGrid, Button, Alert } from "ui";
import ForumIcon from "@mui/icons-material/Forum";
import routes from "default/routes";
import PropTypes from "prop-types";

export default function GiveFeedback({ submissions }) {
  const navigate = useNavigate();

  return (
    <div>
      <CardGrid
        entries={submissions.all}
        card={SubmissionCard}
        onClick={(submission) =>
          navigate(
            `${generatePath(routes.feedbackHubSubmission, {
              submissionId: submission.id,
            })}#feedback`,
          )
        }
        sizing={{
          md: 6,
          lg: 4,
        }}
      />

      <AutoScrollLoader
        fetchNextPage={submissions.fetchNextPage}
        hasNextPage={submissions.hasNextPage}
        isLoading={submissions.isLoading || submissions.isFetchingNextPage}
        error={submissions.error}
        empty={!submissions.all.length}
        className="mt-4"
      >
        <Alert>
          <ForumIcon className="mx-auto mb-4 text-primary-200" />
          <h3 className="text-xl uppercase font-semibold mb-2 text-primary-200">
            You have not given any feedback yet
          </h3>
          <p className="text-gray-600 mb-4">
            Your insights can inspire and elevate fellow designers. Find a submission and give your
            first piece of feedback!
          </p>
          <Button
            as={Link}
            to={routes.voting}
            trackingName="view submissions"
            color="primary"
            size="lg"
          >
            Browse Submissions
          </Button>
        </Alert>
      </AutoScrollLoader>
    </div>
  );
}
GiveFeedback.propTypes = {
  submissions: PropTypes.arrayOf(submissionListPropTypes).isRequired,
};
